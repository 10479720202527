export const redirectList = [
  {
    source: '/products/apps-and-workflows/driver',
    target: '/products/apps-and-workflows',
    status: '301',
  },
  {
    source: '/products/tours',
    target: '/resources/tour',
    status: '301',
  },
  {
    source: '</index\\.html/>',
    target: '/404.html',
    status: '404-200',
  },
  {
    source: '/case-studies',
    target: '/customers',
    status: '301',
  },
  {
    source: '/<lang_code>/case-studies',
    target: '/<lang_code>/customers',
    status: '301',
  },
  {
    source: '/api',
    target: 'https://developers.samsara.com/reference',
    status: '301',
  },
  {
    source: '/<lang_code>/api',
    target: 'https://developers.samsara.com/reference',
    status: '301',
  },
  {
    source: '/sites',
    target: '/products/site-visibility',
    status: '301',
  },
  {
    source: '/<lang_code>/sites',
    target: '/<lang_code>/products/site-visibility',
    status: '301',
  },
  {
    source: '/site-visibility',
    target: '/products/site-visibility',
    status: '301',
  },
  {
    source: '/<lang_code>/site-visibility',
    target: '/<lang_code>/products/site-visibility',
    status: '301',
  },
  {
    source: '/connectedworker',
    target: '/products/site-visibility',
    status: '301',
  },
  {
    source: '/<lang_code>/connectedworker',
    target: '/<lang_code>/products/site-visibility',
    status: '301',
  },
  {
    source: '/workforce/sites',
    target: '/products/site-visibility',
    status: '301',
  },
  {
    source: '/<lang_code>/workforce/sites',
    target: '/<lang_code>/products/site-visibility',
    status: '301',
  },
  {
    source: '/connected-sites',
    target: '/products/site-visibility',
    status: '301',
  },
  {
    source: '/<lang_code>/connected-sites',
    target: '/<lang_code>/products/site-visibility',
    status: '301',
  },
  {
    source: '/connected-sites/site-security',
    target: '/products/site-visibility/site-security',
    status: '301',
  },
  {
    source: '/support/sg1-install-nc1',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360048996891-Network-Setup-1-Site-Gateway-as-a-Camera-Server',
    status: '301',
  },
  {
    source: '/<lang_code>/support/sg1-install-nc1',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360048996891-Network-Setup-1-Site-Gateway-as-a-Camera-Server',
    status: '301',
  },
  {
    source: '/support/sg1-install-nc2',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360048515112-Network-Setup-2-Cameras-in-network-with-Internet',
    status: '301',
  },
  {
    source: '/<lang_code>/support/sg1-install-nc2',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360048515112-Network-Setup-2-Cameras-in-network-with-Internet',
    status: '301',
  },
  {
    source: '/support/sg1-install-nc3',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360049005071-Network-Setup-3-Network-with-Internet-Camera-nework-without-Internet',
    status: '301',
  },
  {
    source: '/<lang_code>/support/sg1-install-nc3',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360049005071-Network-Setup-3-Network-with-Internet-Camera-nework-without-Internet',
    status: '301',
  },
  {
    source: '/pdd/sites',
    target:
      'https://www.samsara.com/connected-sites?utm_medium=page_visit&utm_source=event&utm_campaign=product-demo-days',
    status: '301',
  },
  {
    source: '/activate',
    target: 'https://cloud.samsara.com/activate',
    status: '302',
  },
  {
    source: '/<lang_code>/activate',
    target: 'https://cloud.samsara.com/activate',
    status: '301',
  },
  {
    source: '/start',
    target: 'https://cloud.samsara.com/activate',
    status: '301',
  },
  {
    source: '/<lang_code>/start',
    target: 'https://cloud.samsara.com/activate',
    status: '301',
  },
  {
    source: '/installer/nvr10',
    target: 'https://cloud.samsara.com/installer',
    status: '301',
  },
  {
    source: '/<lang_code>/cookie-consent',
    target: '/<lang_code>/legal/cookie-consent',
    status: '301',
  },
  {
    source: '/cookie-consent',
    target: '/legal/cookie-consent',
    status: '301',
  },
  {
    source:
      '/blog/weathering-the-storm-with-sensors-real-time-data-for-hurricane-restoration',
    target: '/webinars/uniti-fiber-samsara-customer-webinar',
    status: '301',
  },
  {
    source:
      '/<lang_code>/blog/weathering-the-storm-with-sensors-real-time-data-for-hurricane-restoration',
    target: '/<lang_code>/webinars/uniti-fiber-samsara-customer-webinar',
    status: '301',
  },
  {
    source: '/mx/blog/camaras-de-tablero-para-camiones',
    target: '/mx/guides/dash-cams-for-trucks',
    status: '301',
  },
  {
    source:
      '/blog/the-fleet-managers-guide-to-a-safe-efficient-and-compliant-fleet',
    target: '/webinars/fleet-managers-guide-safe-efficient-compliant',
    status: '301',
  },
  {
    source:
      '/<lang_code>/blog/the-fleet-managers-guide-to-a-safe-efficient-and-compliant-fleet',
    target:
      '/<lang_code>/webinars/fleet-managers-guide-safe-efficient-compliant',
    status: '301',
  },
  {
    source:
      '/blog/data-transparency-can-increase-the-lifetime-value-of-vehicles',
    target: '/blog/data-increase-lifetime-value-of-vehicles',
    status: '301',
  },
  {
    source:
      '<lang_code>/blog/data-transparency-can-increase-the-lifetime-value-of-vehicles',
    target: '/<lang_code>/blog/data-increase-lifetime-value-of-vehicles',
    status: '301',
  },
  {
    source: '/industrial/advanced-equipment-monitoring',
    target: '/products/equipment-monitoring/asset-tracking',
    status: '301',
  },
  {
    source: '/industrial/equipment-reporting',
    target: '/products/equipment-monitoring/equipment-reporting',
    status: '301',
  },
  {
    source: '/industrial/custom-dashboards',
    target: '/products/equipment-monitoring/custom-dashboards',
    status: '301',
  },
  {
    source: '/industrial/custom-kpis',
    target: '/products/equipment-monitoring/custom-kpis',
    status: '301',
  },
  {
    source: '/industrial/advanced-alerting',
    target: '/products/equipment-monitoring/advanced-alerting',
    status: '301',
  },
  {
    source: '/industrial/industrial-gateways',
    target: '/products/equipment-monitoring/industrial-gateways',
    status: '301',
  },
  {
    source: '/industrial/oil-gas',
    target: '/industries/oil-gas',
    status: '301',
  },
  {
    source: '/industrial/water-wastewater',
    target: '/industries/water-wastewater',
    status: '301',
  },
  {
    source: '/driver-app-instructions',
    target: '/support/driver-training',
    status: '301',
  },
  {
    source: '/<lang_code>/driver-app-instructions',
    target: '/<lang_code>/support/driver-training',
    status: '301',
  },
  {
    source: '/support',
    target: 'https://kb.samsara.com/hc/en-us',
    status: '301',
  },
  {
    source: '/<lang_code>/support',
    target: 'https://kb.samsara.com/hc/<lang_code>',
    status: '301',
  },
  {
    source: '/help',
    target: 'https://kb.samsara.com/hc/requests/new',
    status: '301',
  },
  {
    source: '/install-HMI10',
    target: 'https://www.samsara.com/pdf/docs/HMI-Install-Guide.pdf',
    status: '301',
  },
  {
    source: '/fleet/dash-cam/collision-avoidance-system',
    target: '/guides/collision-avoidance-system',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/dash-cam/collision-avoidance-system',
    target: '/<lang_code>/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/fleet/dash-cam/wireless-dash-cam',
    target: '/guides/wireless-dash-cam',
    status: '301',
  },
  {
    source: '/fleet/dash-cam/dash-cams-for-trucks',
    target: '/guides/dash-cams-for-trucks',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/dash-cam/dash-cams-for-trucks',
    target: '/guides/dash-cams-for-trucks',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/dash-cam/dash-cams-for-trucks',
    target: '/<lang_code>/guides/dash-cams-for-trucks',
    status: '301',
  },
  {
    source: '/fleet/dash-cam/dash-cam-night-vision',
    target: '/guides/dash-cam-night-vision',
    status: '301',
  },
  {
    source: '/fleet/reporting-and-alerts/ifta',
    target: '/guides/ifta',
    status: '301',
  },
  {
    source: '/fleet/asset-tracking/asset-tracking-software',
    target: '/guides/asset-tracking-software',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance/what-is-the-eld-mandate',
    target: '/guides/what-is-the-eld-mandate',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance/electronic-logging-device',
    target: '/guides/electronic-logging-device',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance/how-it-works',
    target: '/guides/how-the-eld-works',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance/hours-of-service',
    target: '/guides/hours-of-service',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance/eld-for-trucks',
    target: '/guides/eld-for-trucks',
    status: '301',
  },
  {
    source: '/info/what-is-a-dash-cam',
    target: '/guides/what-is-a-dash-cam',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance/eld-news',
    target: '/guides/eld-news',
    status: '301',
  },
  {
    source: '/fleet/reefer-monitoring/temperature-monitoring',
    target: '/guides/temperature-monitoring',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/reefer-monitoring/temperature-monitoring',
    target: '/<lang_code>/guides/temperature-monitoring',
    status: '301',
  },
  {
    source: '/fleet/dash-cam/adas',
    target: '/guides/adas',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/dash-cam/adas',
    target: '/guides/adas',
    status: '301',
  },
  {
    source: '/fleet/what-is-fleet-management',
    target: '/guides/what-is-fleet-management',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/what-is-fleet-management',
    target: '/<lang_code>/guides/what-is-fleet-management',
    status: '301',
  },
  {
    source: '/fleet/fleet-management-software',
    target: '/guides/fleet-management-software',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/fleet-management-software',
    target: '/<lang_code>/guides/fleet-management-software',
    status: '301',
  },
  {
    source: '/fleet/gps-fleet-tracking/what-is-telematics',
    target: '/guides/what-is-telematics',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/gps-fleet-tracking/what-is-telematics',
    target: '/<lang_code>/guides/what-is-telematics',
    status: '301',
  },
  {
    source: '/fleet/gps-fleet-tracking/gps-tracking-for-trucks',
    target: '/guides/gps-tracking-for-trucks',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/gps-fleet-tracking/gps-tracking-for-trucks',
    target: '/<lang_code>/guides/gps-tracking-for-trucks',
    status: '301',
  },
  {
    source: '/products/models/ag15',
    target: '/produccts/models/AG24',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/ag15',
    target: '/<lang_code>/produccts/models/AG24',
    status: '301',
  },
  {
    source: '/products/models/cm11',
    target: '/produccts/models/CM12',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/cm11',
    target: '/<lang_code>/produccts/models/CM12',
    status: '301',
  },
  {
    source: '/products/models/vg32',
    target: '/produccts/models/VG34',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/vg32',
    target: '/<lang_code>/produccts/models/VG34',
    status: '301',
  },
  {
    source: '/products/models/vg33',
    target: '/produccts/models/VG34',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/vg33',
    target: '/<lang_code>/produccts/models/VG34',
    status: '301',
  },
  {
    source: '/products/models/vg34',
    target: '/products/models/vehicle-gateway',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/vg34',
    target: '/<lang_code>/products/models/vehicle-gateway',
    status: '301',
  },
  {
    source: '/products/models/em21',
    target: '/products/models/environmental-monitor',
    status: '301',
  },
  {
    source: '/ca/products/models/em21',
    target: '/ca/products/models/environmental-monitor',
    status: '301',
  },
  {
    source: '/mx/guides/fleet-safety',
    target: '/mx/guias/seguridad-flotas',
    status: '301',
  },
  {
    source: '/engine-immobilizer-setup',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360042094831-Engine-Immobilizer-',
    status: '301',
  },
  {
    source: '/privacy-button-setup',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360047230551-Privacy-Button',
    status: '301',
  },
  {
    source: '/rs232',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360059978871-Materials-Spreaders',
    status: '301',
  },
  {
    source: '/mx/products/equipment-monitoring/reefer-monitoring',
    target: '/mx/productos/rastreo-equipos/refrigerados',
    status: '301',
  },
  {
    source: '/mx/solutions/security',
    target: '/mx/soluciones/seguridad-patrimonial',
    status: '301',
  },
  {
    source: '/mx/products/telematics/gps-fleet-tracking',
    target: '/mx/productos/telematica/rastreo-gps-flota',
    status: '301',
  },
  {
    source: '/mx/productos/rastreo-equipos/rastreo-remolques',
    target: '/mx/products/equipment-monitoring/smart-trailers',
    status: '301',
  },
  {
    source: '/new-features',
    target: 'https://cloud.samsara.com/new_features',
    status: '301',
  },
  {
    source: '/installer',
    target: 'https://cloud.samsara.com/installer',
    status: '301',
  },
  {
    source: '/onboarding',
    target: 'https://kb.samsara.com/hc/en-us/articles/360050215112',
    status: '301',
  },
  {
    source: '/panic-button-setup',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360032833212-Panic-Button-Setup',
    status: '301',
  },
  {
    source: '/events/safety',
    target: '/events',
    status: '301',
  },
  {
    source: '/events',
    target: '/webinars',
    status: '302',
  },
  {
    source: '/ca/events',
    target: '/ca/webinars',
    status: '302',
  },
  {
    source: '/guides/scada-software',
    target: '/guides/scada',
    status: '301',
  },
  {
    source: '/your-partner-for-the-road-ahead/<slug>',
    target: '/guides/your-partner-for-the-road-ahead/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/your-partner-for-the-road-ahead/<slug>',
    target: '/<lang_code>/guides/your-partner-for-the-road-ahead/<slug>',
    status: '301',
  },
  {
    source: '/your-partner-for-the-road-ahead',
    target: '/guides/your-partner-for-the-road-ahead',
    status: '301',
  },
  {
    source: '/<lang_code>/your-partner-for-the-road-ahead',
    target: '/<lang_code>/guides/your-partner-for-the-road-ahead',
    status: '301',
  },
  {
    source: '/developers',
    target: '/support/developers',
    status: '301',
  },
  {
    source: '/<lang_code>/developers',
    target: '/<lang_code>/support/developers',
    status: '301',
  },
  {
    source: '/data-protection-addendum',
    target: '/legal/data-protection-addendum',
    status: '301',
  },
  {
    source: '/<lang_code>/data-protection-addendum',
    target: '/<lang_code>/legal/data-protection-addendum',
    status: '301',
  },
  {
    source: '/terms-of-service',
    target: '/legal/platform-terms-of-service',
    status: '301',
  },
  {
    source: '/pdf/terms-of-service.pdf',
    target: '/legal/platform-terms-of-service',
    status: '301',
  },
  {
    source: '/<lang_code>/terms-of-service',
    target: '/<lang_code>/legal/platform-terms-of-service',
    status: '301',
  },
  {
    source: '/express-order-terms',
    target: '/legal/express-order-terms',
    status: '301',
  },
  {
    source: '/<lang_code>/express-order-terms',
    target: '/<lang_code>/legal/express-order-terms',
    status: '301',
  },
  {
    source: '/hosted-software-sla',
    target: '/legal/hosted-software-sla',
    status: '301',
  },
  {
    source: '/<lang_code>/hosted-software-sla',
    target: '/<lang_code>/legal/hosted-software-sla',
    status: '301',
  },
  {
    source: '/website-terms-of-service',
    target: '/legal/website-terms-of-service',
    status: '301',
  },
  {
    source: '/<lang_code>/website-terms-of-service',
    target: '/<lang_code>/legal/website-terms-of-service',
    status: '301',
  },
  {
    source: '/cookie-policy',
    target: '/legal/cookie-policy',
    status: '301',
  },
  {
    source: '/<lang_code>/cookie-policy',
    target: '/<lang_code>/legal/cookie-policy',
    status: '301',
  },
  {
    source: '/contact',
    target: '/company/contact',
    status: '301',
  },
  {
    source: '/<lang_code>/contact',
    target: '/<lang_code>/company/contact',
    status: '301',
  },
  {
    source: '/about',
    target: '/company/about',
    status: '301',
  },
  {
    source: '/<lang_code>/about',
    target: '/<lang_code>/company/about',
    status: '301',
  },
  {
    source: '/topfleets2020',
    target: '/customers/topfleets/2020',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2020',
    target: '/<lang_code>/customers/topfleets/2020',
    status: '301',
  },
  {
    source: '/fleet/platform',
    target: '/products/platform',
    status: '301',
  },
  {
    source: '/fleet',
    target: '/products/telematics',
    status: '301',
  },
  {
    source: '/mx/flota',
    target: '/mx/products/telematics',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet',
    target: '/<lang_code>/products/telematics',
    status: '301',
  },
  {
    source: '/fleet/safety/driver',
    target: '/products/safety/driver',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/safety/driver',
    target: '/<lang_code>/products/safety',
    status: '301',
  },
  {
    source: '/fleet/gps-fleet-tracking',
    target: '/products/telematics/gps-fleet-tracking',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/gps-fleet-tracking',
    target: '/<lang_code>/products/telematics/gps-fleet-tracking',
    status: '301',
  },
  {
    source: '/fleet/connected-driver/routing',
    target: '/products/telematics/routing',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/connected-driver/routing',
    target: '/<lang_code>/products/telematics/routing',
    status: '301',
  },
  {
    source: '/fleet/connected-driver/fuel-and-maintenance',
    target: '/products/telematics/fuel-and-maintenance',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/fuel-and-maintenance',
    target: '/<lang_code>/products/telematics/fuel-and-maintenance',
    status: '301',
  },
  {
    source: '/fleet/telematics/electric-vehicles',
    target: '/products/telematics/electric-vehicles',
    status: '301',
  },
  {
    source: '/fleet/oem-telematics',
    target: '/products/telematics/oem-integrations',
    status: '301',
  },
  {
    source: '/fleet/reporting-and-alerts',
    target: '/products/reporting-and-alerts',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/reporting-and-alerts',
    target: '/<lang_code>/uk/products/reporting-and-alerts',
    status: '301',
  },
  {
    source: '/fleet/connected-driver/driver-workflow',
    target: '/products/apps-and-workflows',
    status: '301',
  },
  {
    source: '/fleet/connected-driver/dvir',
    target: '/products/apps-and-workflows/dvir',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/connected-driver/dvir',
    target: '/<lang_code>/products/apps-and-workflows/dvir',
    status: '301',
  },
  {
    source: '/fleet/connected-driver/document-management',
    target: '/products/apps-and-workflows/document-management',
    status: '301',
  },
  {
    source: '/fleet/connected-driver/eld-compliance',
    target: '/products/apps-and-workflows/eld-compliance',
    status: '301',
  },
  {
    source: '/fleet/trailer-tracking',
    target: '/products/equipment-monitoring/smart-trailers',
    status: '301',
  },
  {
    source: '/fleet/equipment-monitoring',
    target: '/products/equipment-monitoring',
    status: '301',
  },
  {
    source: '/fleet/equipment-tracking',
    target: '/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/fleet/reefer-monitoring',
    target: '/products/equipment-monitoring/reefer-monitoring',
    status: '301',
  },
  {
    source: '/fleet/solutions-efficiency',
    target: '/solutions/efficiency',
    status: '301',
  },
  {
    source: '/fleet/solutions-safety',
    target: '/solutions/safety',
    status: '301',
  },
  {
    source: '/fleet/solutions-sustainability',
    target: '/solutions/sustainability',
    status: '301',
  },
  {
    source: '/fleet/solutions-compliance',
    target: '/solutions/compliance',
    status: '301',
  },
  {
    source: '/fleet/solutions-security',
    target: '/solutions/security',
    status: '301',
  },
  {
    source: '/fleet/solutions-customerexperience',
    target: '/solutions/customer-experience',
    status: '301',
  },
  {
    source: '/fleet/logistics',
    target: '/industries/logistics',
    status: '301',
  },
  {
    source: '/fleet/food-and-beverage',
    target: '/industries/food-and-beverage',
    status: '301',
  },
  {
    source: '/careers/<slug>',
    target: '/company/careers/<slug>',
    status: '301',
  },
  {
    source: '/careers',
    target: '/company/careers',
    status: '301',
  },
  {
    source: '/<lang_code>/careers',
    target: '/<lang_code>/company/careers',
    status: '301',
  },
  {
    source: '/mx/careers',
    target: '/company/careers',
    status: '301',
  },
  {
    source: '/fleet/construction',
    target: '/industries/construction',
    status: '301',
  },
  {
    source: '/products/construction',
    target: '/industries/construction',
    status: '301',
  },
  {
    source: '/<lang_code>/products/contruction',
    target: '/<lang_code>/industries/construction',
    status: '301',
  },
  {
    source: '/industries/construction-deprecated',
    target: '/industries/construction',
    status: '301',
  },
  {
    source: '/fleet/state-local-government',
    target: '/industries/state-local-government',
    status: '301',
  },
  {
    source: '/fleet/k-12',
    target: '/industries/k-12',
    status: '301',
  },
  {
    source: '/fleet/higher-education',
    target: '/industries/higher-education',
    status: '301',
  },
  {
    source: '/fleet/passenger-transit',
    target: '/industries/passenger-transit',
    status: '301',
  },
  {
    source: '/fleet/field-services',
    target: '/industries/field-services',
    status: '301',
  },
  {
    source: '/fleet/utilities',
    target: '/industries/utilitie',
    status: '301',
  },
  {
    source: '/mx/boton-de-panico-setup',
    target:
      'https://kb.samsara.com/hc/es/articles/360032833212-C%C3%B3mo-configurar-el-Bot%C3%B3n-de-P%C3%A1nico-en-el-panel-web',
    status: '301',
  },
  {
    source: '/recruiting-events',
    target: '/company/careers',
    status: '302',
  },
  {
    source: '/<lang_code>/recruiting-events',
    target: '/<lang_code>/company/careers',
    status: '302',
  },
  {
    source: '/developers/changelog',
    target: 'https://developers.samsara.com/changelog',
    status: '301',
  },
  {
    source: '/<lang_code>/developers/changelog',
    target: 'https://developers.samsara.com/changelog',
    status: '301',
  },
  {
    source: '/eng-events',
    target: '/careers/jobs',
    status: '302',
  },
  {
    source: '/<lang_code>/eng-events',
    target: '/careers/jobs',
    status: '302',
  },
  {
    source: '/page/mcgriff',
    target: '/partners/register',
    status: '301',
  },
  {
    source: '/page/canal',
    target: '/partners/register',
    status: '301',
  },
  {
    source: '/myreferrals',
    target: '/my-referrals',
    status: '301',
  },
  {
    source: '/<lang_code>/myreferrals',
    target: '/my-referrals',
    status: '301',
  },
  {
    source: '/partners/deal-reg',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/uk/partners/deal-reg',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/partners/deal-reg/industrial',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/uk/partners/deal-reg/industrial',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/partners/referral',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/uk/partners/referral',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/partners/business-registration',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/uk/partners/business-registration',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/partners/referral-ryder',
    target: 'https://www.partners-samsara.com',
    status: '301',
  },
  {
    source: '/marketplace',
    target: '/resources/marketplace',
    status: '301',
  },
  {
    source: '/<lang_code>/marketplace',
    target: '/resources/marketplace',
    status: '301',
  },
  {
    source: '/referrals/fleetviewer',
    target:
      '/fleet?utm_campaign=referrals&utm_source=website&utm_content=live-share',
    status: '301',
  },
  {
    source: '/learn',
    target: 'https://cloud.samsara.com/samsaraacademy',
    status: '301',
  },
  {
    source: '/<lang_code>/learn',
    target: 'https://cloud.samsara.com/samsaraacademy',
    status: '301',
  },
  {
    source: '/install',
    target: '/videos/?category=installation-guides#videos',
    status: '301',
  },
  {
    source: '/<lang_code>/install',
    target: '/videos/?category=installation-guides#videos',
    status: '301',
  },
  {
    source: '/jobs',
    target: '/company/careers/roles',
    status: '301',
  },
  {
    source: '/<lang_code>/jobs',
    target: '/<lang_code>/company/careers/roles',
    status: '301',
  },
  {
    source: '/company/careers/jobs',
    target: '/company/careers/roles',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/jobs',
    target: '/<lang_code>/company/careers/roles',
    status: '301',
  },
  {
    source: '/careers',
    target: '/company/careers',
    status: '301',
  },
  {
    source: '/careers/<slug>',
    target: '/company/careers/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/careers/<slug>',
    target: '/<lang_code>/company/careers/<slug>',
    status: '301',
  },
  {
    source: '/diversity',
    target: '/company/diversity',
    status: '301',
  },
  {
    source: '/<lang_code>/diversity',
    target: '/<lang_code>/company/diversity',
    status: '301',
  },
  {
    source: '/experts/<slug>',
    target: '/resources/experts/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/experts/<slug>',
    target: '/<lang_code>/resources/experts/<slug>',
    status: '301',
  },
  {
    source: '/careers/benefits',
    target: '/company/careers/benefits',
    status: '301',
  },
  {
    source: '/<lang_code>/careers/benefits',
    target: '/<lang_code>/company/careers/benefits',
    status: '301',
  },
  {
    source: '/marketplace/<slug>',
    target: '/resources/marketplace/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/marketplace/<slug>',
    target: '/<lang_code>/resources/marketplace/<slug>',
    status: '301',
  },
  {
    source: '/experts',
    target: '/resources/experts',
    status: '301',
  },
  {
    source: '/<lang_code>/experts',
    target: '/<lang_code>/resources/experts',
    status: '301',
  },
  {
    source: '/guide-to-fleet-savings',
    target: '/guides/fleet-savings',
    status: '301',
  },
  {
    source: '/<lang_code>/guide-to-fleet-savings',
    target: '/<lang_code>/guides/fleet-savings',
    status: '301',
  },
  {
    source: '/safety/guide-to-fleet-safety',
    target: '/guides/fleet-safety',
    status: '301',
  },
  {
    source: '/<lang_code>/safety/guide-to-fleet-safety',
    target: '/<lang_code>/guides/fleet-safety',
    status: '301',
  },
  {
    source: '/support/candidate-privacy-notice',
    target: '/legal/candidate-privacy-notice',
    status: '301',
  },
  {
    source: '/<lang_code>/support/candidate-privacy-notice',
    target: '/<lang_code>/legal/candidate-privacy-notice',
    status: '301',
  },
  {
    source: "/safety/guide-to-fleet-safety/fleet-safety'",
    target: '/guides/fleet-safety/safety-program',
    status: '301',
  },
  {
    source: "/<lang_code>/safety/guide-to-fleet-safety/fleet-safety'",
    target: '/<lang_code>/guides/fleet-safety/safety-program',
    status: '301',
  },
  {
    source: '/safety/guide-to-fleet-safety/<slug>',
    target: '/guides/fleet-safety/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/safety/guide-to-fleet-safety/<slug>',
    target: '/<lang_code>/guides/fleet-safety/<slug>',
    status: '301',
  },
  {
    source: '/process-monitoring',
    target: '/products/equipment-monitoring/process-monitoring',
    status: '301',
  },
  {
    source: '/<lang_code>/process-monitoring',
    target: '/<lang_code>/products/equipment-monitoring/process-monitoring',
    status: '301',
  },
  {
    source: '/industrial/monitoring-control',
    target: '/products/equipment-monitoring',
    status: '301',
  },
  {
    source: '/<lang_code>/industrial/monitoring-control',
    target: '/<lang_code>/products/equipment-monitoring',
    status: '301',
  },
  {
    source: '/condition-monitoring',
    target: '/products/equipment-monitoring/condition-monitoring',
    status: '301',
  },
  {
    source: '/<lang_code>/condition-monitoring',
    target: '/<lang_code>/products/equipment-monitoring/condition-monitoring',
    status: '301',
  },
  {
    source: '/industrial/condition-monitoring',
    target: '/products/equipment-monitoring/condition-monitoring',
    status: '301',
  },
  {
    source: '/<lang_code>/industrial/condition-monitoring',
    target: '/<lang_code>/products/equipment-monitoring/condition-monitoring',
    status: '301',
  },
  {
    source: '/privacy',
    target: '/company/privacy',
    status: '301',
  },
  {
    source: '/<lang_code>/privacy',
    target: '/<lang_code>/company/privacy',
    status: '301',
  },
  {
    source: '/fleet/asset-tracking',
    target: '/products/equipment-monitoring/asset-tracking',
    status: '301',
  },
  {
    source: '/uk/fleet/asset-tracking',
    target: '/uk/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/fr/fleet/asset-tracking',
    target: '/fr/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/de/fleet/asset-tracking',
    target: '/de/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/nl/fleet/asset-tracking',
    target: '/nl/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/fr-ca/fleet/asset-tracking',
    target: '/nl/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/industrial/asset-tracking',
    target: '/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/<lang_code>/industrial/asset-tracking',
    target: '/<lang_code>/products/equipment-monitoring/equipment-tracking',
    status: '301',
  },
  {
    source: '/fleet/eld-compliance',
    target: '/products/apps-and-workflows/eld-compliance',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/eld-compliance',
    target: '/<lang_code>/products/apps-and-workflows/eld-compliance',
    status: '301',
  },
  {
    source: '/eld',
    target: '/products/apps-and-workflows/eld-compliance',
    status: '301',
  },
  {
    source: '/<lang_code>/eld',
    target: '/<lang_code>/products/apps-and-workflows/eld-compliance',
    status: '301',
  },
  {
    source: '/fleet/product-tours',
    target: '/resources/tour',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/product-tours',
    target: '/<lang_code>/resources/tour',
    status: '301',
  },
  {
    source: '/electric-vehicles',
    target: '/products/telematics/electric-vehicles',
    status: '301',
  },
  {
    source: '/<lang_code>/electric-vehicles',
    target: '/<lang_code>/products/telematics/electric-vehicles',
    status: '301',
  },
  {
    source: '/tips/<slug>',
    target: '/customers/tips/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/tips/<slug>',
    target: '/<lang_code>/customers/tips/<slug>',
    status: '301',
  },
  {
    source: '/tips',
    target: '/customers/tips',
    status: '301',
  },
  {
    source: '/<lang_code>/tips',
    target: '/<lang_code>/customers/tips',
    status: '301',
  },
  {
    source: '/<lang_code>/jobs',
    target: '/<lang_code>/company/careers/roles',
    status: '301',
  },
  {
    source: '/topfleets2019/above-beyond',
    target: '/customers/topfleets/2019/above-beyond',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2019/above-beyond',
    target: '/<lang_code>/customers/topfleets/2019/above-beyond',
    status: '301',
  },
  {
    source: '/topfleets2019/safest-fleet',
    target: '/customers/topfleets/2019/safest-fleet',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2019/safest-fleet',
    target: '/<lang_code>/customers/topfleets/2019/safest-fleet',
    status: '301',
  },
  {
    source: '/sustainability',
    target: '/company/mission/sustainability',
    status: '301',
  },
  {
    source: '/<lang_code>/sustainability',
    target: '/<lang_code>/company/mission/sustainability',
    status: '301',
  },
  {
    source: '/safety',
    target: '/company/mission/safety',
    status: '301',
  },
  {
    source: '/<lang_code>/safety',
    target: '/<lang_code>/company/mission/safety',
    status: '301',
  },
  {
    source: '/efficiency',
    target: '/company/mission/efficiency',
    status: '301',
  },
  {
    source: '/<lang_code>/efficiency',
    target: '/<lang_code>/company/mission/efficiency',
    status: '301',
  },
  {
    source: '/security',
    target: '/legal/security',
    status: '301',
  },
  {
    source: '/<lang_code>/security',
    target: '/<lang_code>/legal/security',
    status: '301',
  },
  {
    source: '/news',
    target: '/company/news',
    status: '301',
  },
  {
    source: '/<lang_code>/news',
    target: '/<lang_code>/company/news',
    status: '301',
  },
  {
    source: '/topfleets2020/<slug>',
    target: '/customers/topfleets/2020/<slug>',
    status: '301',
  },
  {
    source: '/company/samsara-ventures',
    target: '/samsara-ventures',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2020/<slug>',
    target: '/<lang_code>/customers/topfleets/2020/<slug>',
    status: '301',
  },
  {
    source: '/topfleets2019/excellence-performance',
    target: '/customers/topfleets/2019/excellence-performance',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2019/excellence-performance',
    target: '/<lang_code>/customers/topfleets/2019/excellence-performance',
    status: '301',
  },
  {
    source: '/topfleets2019/excellence-performance',
    target: '/customers/topfleets/2019/excellence-performance',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2019/excellence-performance',
    target: '/<lang_code>/customers/topfleets/2019/excellence-performance',
    status: '301',
  },
  {
    source: '/topfleets2019',
    target: '/customers/topfleets/2019',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets2019',
    target: '/<lang_code>/customers/topfleets/2019',
    status: '301',
  },
  {
    source: '/topfleets',
    target: '/customers/topfleets',
    status: '301',
  },
  {
    source: '/<lang_code>/topfleets',
    target: '/<lang_code>/customers/topfleets',
    status: '301',
  },
  {
    source: '/brand-assets',
    target: '/resources/brand-assets',
    status: '301',
  },
  {
    source: '/<lang_code>/brand-assets',
    target: '/<lang_code>/resources/brand-assets',
    status: '301',
  },
  {
    source: '/content/<slug>',
    target: '/resources/content/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/content/<slug>',
    target: '/<lang_code>/resources/content/<slug>',
    status: '301',
  },
  {
    source: '/partner-programs/<slug>',
    target: '/resources/partner-programs/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/partner-programs/<slug>',
    target: '/<lang_code>/resources/partner-programs/<slug>',
    status: '301',
  },
  {
    source: '/tech-partners',
    target: '/resources/partner-programs',
    status: '301',
  },
  {
    source: '/<lang_code>/tech-partners',
    target: '/<lang_code>/resources/partner-programs',
    status: '301',
  },
  {
    source: '/partner-programs',
    target: '/resources/partner-programs',
    status: '301',
  },
  {
    source: '/<lang_code>/partner-programs',
    target: '/<lang_code>/resources/partner-programs',
    status: '301',
  },
  {
    source: '/cm22',
    target: '/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/fleet/dash',
    target: '/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/fleet/dash-cam',
    target: '/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/dashcam',
    target: '/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/<lang_code>/cm22',
    target: '/<lang_code>/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/dash',
    target: '/<lang_code>/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/dash-cam',
    target: '/<lang_code>/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/<lang_code>/dashcam',
    target: '/<lang_code>/products/safety/dash-cam',
    status: '301',
  },
  {
    source: '/fleet/document-management',
    target: '/products/apps-and-workflows/document-management',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/document-management',
    target: '/<lang_code>/products/apps-and-workflows/document-management',
    status: '301',
  },
  {
    source: '/api-preview',
    target: 'https://developers.samsara.com/reference/overview',
    status: '301',
  },
  {
    source: '/<lang_code>/api-preview',
    target: 'https://developers.samsara.com/reference/overview',
    status: '301',
  },
  {
    source: '/fleet/routing',
    target: '/products/telematics/routing',
    status: '301',
  },
  {
    source: '/<lang_code>/fleet/routing',
    target: '/<lang_code>/products/telematics/routing',
    status: '301',
  },
  {
    source: '/videos',
    target: '/resources/videos',
    status: '301',
  },
  {
    source: '/<lang_code>/videos',
    target: '/<lang_code>/resources/videos',
    status: '301',
  },
  {
    source: '/support/install-videos',
    target: '/resources/videos/?category=installation-guides',
    status: '301',
  },
  {
    source: '/<lang_code>/support/install-videos',
    target: '/<lang_code>/resources/videos/?category=installation-guides',
    status: '301',
  },
  {
    source: '/partners/apply',
    target: 'https://www.partners-samsara.com/English/register_email.aspx',
    status: '301',
  },
  {
    source: '/<lang_code>/partners/apply',
    target: 'https://www.partners-samsara.com/English/register_email.aspx',
    status: '301',
  },
  {
    source: '/getting-started',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/getting-started',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/getting-started/',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/getting-started/',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/free-trial',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/free-trial',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/free-trial/',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/free-trial/',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/learn-more',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/learn-more',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/learn-more/',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/learn-more/',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/uk/products/apps-and-workflows/eld-compliance',
    target: '/uk/products/telematics/tachograph',
    status: '301',
  },
  {
    source: '/uk/fleet/tachograph',
    target: '/uk/products/telematics/tachograph',
    status: '301',
  },
  {
    source: '/at/<slug>',
    target: '/de/<slug>',
    status: '301',
  },
  {
    source: '/at',
    target: '/de',
    status: '301',
  },
  {
    source: '/ch',
    target: '/de',
    status: '301',
  },
  {
    source: '/ch/<slug>',
    target: '/de/<slug>',
    status: '301',
  },
  {
    source: '/be/blog/category/company-news',
    target: '/be/company/news',
    status: '301',
  },
  {
    source: '/nl/blog/category/company-news',
    target: '/nl/company/news',
    status: '301',
  },
  {
    source: '/fleet-terms/<slug>',
    target: '/guides/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/static/pdf/hardware-warranty.pdf',
    target: '/<lang_code>/support/hardware-warranty',
    status: '301',
  },
  {
    source: '/<lang_code>/hardware-warranty',
    target: '/<lang_code>/support/hardware-warranty',
    status: '301',
  },
  {
    source: '/static/pdf/hardware-warranty.pdf',
    target: '/support/hardware-warranty',
    status: '301',
  },
  {
    source: '/hardware-warranty',
    target: '/support/hardware-warranty',
    status: '301',
  },
  {
    source: '/<lang_code>/support/install-videos',
    target: '/<lang_code>/videos/?category=installation-guides#videos',
    status: '301',
  },
  {
    source: '/support/install-videos',
    target: '/videos/?category=installation-guides#videos',
    status: '301',
  },
  {
    source: '/Spark',
    target: '/spark',
    status: '301',
  },
  {
    source: '/<lang_code>/customers/liberty-oilfield-services',
    target: '/<lang_code>/customers/liberty-energy',
    status: '301',
  },
  {
    source: '/customers/liberty-oilfield-services',
    target: '/customers/liberty-energy',
    status: '301',
  },
  {
    source: '/customers/fort-point-beer',
    target: '/blog/customer-spotlight-samsara-x-fort-point-beer',
    status: '301',
  },
  {
    source: '/<lang_code>/guides/fleet-savings',
    target: '/<lang_code>/guides/build-business-resilience',
    status: '301',
  },
  {
    source: '/guides/fleet-savings',
    target: '/guides/build-business-resilience',
    status: '301',
  },
  {
    source: '/<lang_code>/guides/fleet-savings/reduce-costs',
    target:
      '/<lang_code>/guides/build-business-resilience/increase-efficiency-maximize-profits',
    status: '301',
  },
  {
    source: '/guides/fleet-savings/reduce-costs',
    target:
      '/guides/build-business-resilience/increase-efficiency-maximize-profits',
    status: '301',
  },
  {
    source: '/<lang_code>/guides/fleet-savings/do-more-with-less',
    target:
      '/<lang_code>/guides/build-business-resilience/attract-retain-talent',
    status: '301',
  },
  {
    source: '/guides/fleet-savings/do-more-with-less',
    target: '/guides/build-business-resilience/attract-retain-talent',
    status: '301',
  },
  {
    source: '/<lang_code>/guides/fleet-savings/retain-customers',
    target:
      '/<lang_code>/guides/build-business-resilience/protect-optimize-assets',
    status: '301',
  },
  {
    source: '/guides/fleet-savings/retain-customers',
    target: '/guides/build-business-resilience/protect-optimize-assets',
    status: '301',
  },
  {
    source: '/home-b',
    target: '',
    status: '301',
  },
  {
    source: '/<lang_code>/home-b',
    target: '/<lang_code>',
    status: '301',
  },
  {
    source: '/home',
    target: '',
    status: '301',
  },
  {
    source: '/<lang_code>/home',
    target: '/<lang_code>',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/engineering',
    target: '/<lang_code>/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/company/careers/engineering',
    target: '/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/de/company/careers/departments/engineering-and-product',
    target: '/uk/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/fr/company/careers/departments/engineering-and-product',
    target: '/uk/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/nl/company/careers/departments/engineering-and-product',
    target: '/uk/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/mx/company/careers/departments/engineering-and-product',
    target: '/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/sf',
    target: '/<lang_code>/company/careers/locations',
    status: '301',
  },
  {
    source: '/de/company/careers/locations',
    target: '/uk/company/careers/locations',
    status: '301',
  },
  {
    source: '/fr/company/careers/locations',
    target: '/uk/company/careers/locations',
    status: '301',
  },
  {
    source: '/nl/company/careers/locations',
    target: '/uk/company/careers/locations',
    status: '301',
  },
  {
    source: '/mx/company/careers/locations',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/company/careers/sf',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/atlanta',
    target: '/<lang_code>/company/careers/locations',
    status: '301',
  },
  {
    source: '/company/careers/atlanta',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/london',
    target: '/<lang_code>/company/careers/locations',
    status: '301',
  },
  {
    source: '/company/careers/london',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/munich',
    target: '/<lang_code>/company/careers/locations',
    status: '301',
  },
  {
    source: '/company/careers/munich',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/paris',
    target: '/<lang_code>/company/careers/locations',
    status: '301',
  },
  {
    source: '/company/careers/paris',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/amsterdam',
    target: '/<lang_code>/company/careers/locations',
    status: '301',
  },
  {
    source: '/company/careers/amsterdam',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/<lang_code>/company/careers/early-careers',
    target: '/<lang_code>/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/de/company/careers/emerging-talent',
    target: '/uk/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/fr/company/careers/emerging-talent',
    target: '/uk/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/nl/company/careers/emerging-talent',
    target: '/uk/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/mx/company/careers/emerging-talent',
    target: '/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/company/careers/early-careers',
    target: '/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/de/company/careers',
    target: '/uk/company/careers',
    status: '301',
  },
  {
    source: '/nl/company/careers',
    target: '/uk/company/careers',
    status: '301',
  },
  {
    source: '/fr/company/careers',
    target: '/uk/company/careers',
    status: '301',
  },
  {
    source: '/mx/company/careers',
    target: '/company/careers',
    status: '301',
  },
  {
    source: '/de/company/careers/departments',
    target: '/uk/company/careers/departments',
    status: '301',
  },
  {
    source: '/nl/company/careers/departments',
    target: '/uk/company/careers/departments',
    status: '301',
  },
  {
    source: '/fr/company/careers/departments',
    target: '/uk/company/careers/departments',
    status: '301',
  },
  {
    source: '/mx/company/careers/departments',
    target: '/company/careers/departments',
    status: '301',
  },
  {
    source: '/fr/company/careers/benefits',
    target: '/uk/company/careers/benefits',
    status: '301',
  },
  {
    source: '/nl/company/careers/benefits',
    target: '/uk/company/careers/benefits',
    status: '301',
  },
  {
    source: '/de/company/careers/benefits',
    target: '/uk/company/careers/benefits',
    status: '301',
  },
  {
    source: '/mx/company/careers/benefits',
    target: '/company/careers/benefits',
    status: '301',
  },
  {
    source: '/sitemap',
    target: '/sitemap.xml',
    status: '301',
  },
  {
    source: '/static/pdf/privacy.pdf',
    target: '/support/privacy',
    status: '301',
  },
  {
    source: '/<lang_code>/static/pdf/privacy.pdf',
    target: '/<lang_code>/support/privacy',
    status: '301',
  },
  {
    source: '/products/models/ag46',
    target: '/products/models/unpowered-asset-gateway',
    status: '301',
  },
  {
    source: '/products/models/ag46p',
    target: '/products/models/powered-asset-gateway',
    status: '301',
  },
  {
    source: '/mx/products/models/ag46',
    target: '/mx/products/models/powered-asset-gateway',
    status: '301',
  },
  {
    source: '/mx/products/models/ag46p',
    target: '/mx/products/models/powered-asset-gateway',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/ag46p',
    target: '/<lang_code>/products/models/powered-asset-gateway',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/ag46',
    target: '/<lang_code>/products/models/unpowered-asset-gateway',
    status: '301',
  },
  {
    source: '/mx/pricing',
    target: '/mx/prueba-gratis',
    status: '301',
  },
  {
    source: '/<lang_code>/products/safety/driver',
    target: '/<lang_code>/products/safety/driver-coaching',
    status: '301',
  },
  {
    source: '/products/safety/driver',
    target: '/products/safety/driver-coaching/',
    status: '301',
  },
  {
    source: '/products/models/sg1',
    target: '/products/models/sg1-g',
    status: '302',
  },
  {
    source: '/ca/products/models/sg1',
    target: '/ca/products/models/sg1-g',
    status: '302',
  },
  {
    source: '/mx/company/careers',
    target: '/company/careers',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers',
    target: '/ca/company/careers',
    status: '301',
  },
  {
    source: '/fr/company/careers',
    target: '/uk/company/careers',
    status: '301',
  },
  {
    source: '/nl/company/careers',
    target: '/uk/company/careers',
    status: '301',
  },
  {
    source: '/de/company/careers',
    target: '/uk/company/careers',
    status: '301',
  },
  {
    source: '/mx/company/careers/benefits',
    target: '/company/careers/benefits',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/benefits',
    target: '/ca/company/careers/benefits',
    status: '301',
  },
  {
    source: '/fr/company/careers/benefits',
    target: '/uk/company/careers/benefits',
    status: '301',
  },
  {
    source: '/nl/company/careers/benefits',
    target: '/uk/company/careers/benefits',
    status: '301',
  },
  {
    source: '/de/company/careers/benefits',
    target: '/uk/company/careers/benefits',
    status: '301',
  },
  {
    source: '/mx/company/careers/departments',
    target: '/company/careers/departments',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/departments',
    target: '/ca/company/careers/departments',
    status: '301',
  },
  {
    source: '/fr/company/careers/departments',
    target: '/uk/company/careers/departments',
    status: '301',
  },
  {
    source: '/nl/company/careers/departments',
    target: '/uk/company/careers/departments',
    status: '301',
  },
  {
    source: '/de/company/careers/departments',
    target: '/uk/company/careers/departments',
    status: '301',
  },
  {
    source: '/mx/company/careers/departments/engineering-and-product',
    target: '/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/departments/engineering-and-product',
    target: '/ca/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/fr/company/careers/departments/engineering-and-product',
    target: '/uk/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/nl/company/careers/departments/engineering-and-product',
    target: '/uk/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/de/company/careers/departments/engineering-and-product',
    target: '/uk/company/careers/departments/engineering-and-product',
    status: '301',
  },
  {
    source: '/mx/company/careers/departments/sales',
    target: '/company/careers/departments/sales',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/departments/sales',
    target: '/ca/company/careers/departments/sales',
    status: '301',
  },
  {
    source: '/fr/company/careers/departments/sales',
    target: '/uk/company/careers/departments/sales',
    status: '301',
  },
  {
    source: '/nl/company/careers/departments/sales',
    target: '/uk/company/careers/departments/sales',
    status: '301',
  },
  {
    source: '/de/company/careers/departments/sales',
    target: '/uk/company/careers/departments/sales',
    status: '301',
  },
  {
    source: '/mx/company/careers/locations',
    target: '/company/careers/locations',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/locations',
    target: '/ca/company/careers/locations',
    status: '301',
  },
  {
    source: '/fr/company/careers/locations',
    target: '/uk/company/careers/locations',
    status: '301',
  },
  {
    source: '/nl/company/careers/locations',
    target: '/uk/company/careers/locations',
    status: '301',
  },
  {
    source: '/de/company/careers/locations',
    target: '/uk/company/careers/locations',
    status: '301',
  },
  {
    source: '/mx/company/careers/emerging-talent',
    target: '/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/emerging-talent',
    target: '/ca/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/fr/company/careers/emerging-talent',
    target: '/uk/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/nl/company/careers/emerging-talent',
    target: '/uk/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/de/company/careers/emerging-talent',
    target: '/uk/company/careers/emerging-talent',
    status: '301',
  },
  {
    source: '/mx/company/careers/roles',
    target: '/company/careers/roles',
    status: '301',
  },
  {
    source: '/fr-ca/company/careers/roles',
    target: '/ca/company/careers/roles',
    status: '301',
  },
  {
    source: '/fr/company/careers/roles',
    target: '/uk/company/careers/roles',
    status: '301',
  },
  {
    source: '/nl/company/careers/roles',
    target: '/uk/company/careers/roles',
    status: '301',
  },
  {
    source: '/de/company/careers/roles',
    target: '/uk/company/careers/roles',
    status: '301',
  },
  {
    source: '/customers/its-conglobal',
    target: '/customers/conglobal',
    status: '301',
  },
  {
    source: '/<lang_code>/customers/its-conglobal',
    target: '/<lang_code>/customers/conglobal',
    status: '301',
  },
  {
    source: '/products/equipment-monitoring/advanced-monitoring',
    target: '/products/equipment-monitoring/asset-tracking',
    status: '301',
  },
  {
    source: '/ca/products/equipment-monitoring/advanced-monitoring',
    target: '/ca/products/equipment-monitoring/asset-tracking',
    status: '301',
  },
  {
    source: '/cm-install',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360042766772-Install-a-Dash-Cam',
    status: '301',
  },
  {
    source: '/vg-install',
    target:
      'https://kb.samsara.com/hc/en-us/articles/360043133311-Install-a-Vehicle-Gateway-VG-',
    status: '301',
  },
  {
    source: '/referrals/',
    target: '/referrals',
    status: '301',
  },
  {
    source: '/support/privacy/special-features',
    target: '/support/biometric-data-retention-and-destruction-policy',
    status: '301',
  },
  {
    source: '/<lang_code>/support/privacy/special-features',
    target:
      '/<lang_code>/support/biometric-data-retention-and-destruction-policy',
    status: '301',
  },
  {
    source: '/uk/fleet',
    target: '/uk/products/telematics',
    status: '301',
  },
  {
    source: '/guides/nvr-software',
    target: '/guides/nvr-camera-system',
    status: '301',
  },
  {
    source: '/guides/warehouse-safety-checklist',
    target: '/guides/most-common-osha-violations',
    status: '301',
  },
  {
    source: '/guides/site-safety-training',
    target: '/guides/most-common-osha-violations',
    status: '301',
  },
  {
    source: '/guides/workplace-hazards',
    target: '/guides/most-common-osha-violations',
    status: '301',
  },
  {
    source: '/guides/industrial-safety-topics',
    target:
      '/guides/what-to-include-in-a-workplace-safety-inspection-checklist',
    status: '301',
  },
  {
    source: '/guides/workplace-safety-training',
    target: '/guides/most-common-osha-violations',
    status: '301',
  },
  {
    source: '/products/models/sc21',
    target: '/products/models/sg1-g',
    status: '301',
  },
  {
    source: '/products/models/cm31',
    target: '/products/models/front-facing-ai-dash-cam',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/cm31',
    target: '/<lang_code>/products/models/front-facing-ai-dash-cam',
    status: '301',
  },
  {
    source: '/products/models/cm32',
    target: '/products/models/dual-facing-ai-dash-cam',
    status: '301',
  },
  {
    source: '/<lang_code>/products/models/cm32',
    target: '/<lang_code>/products/models/dual-facing-ai-dash-cam',
    status: '301',
  },
  {
    source: '/at11-install',
    target:
      'https://kb.samsara.com/hc/articles/27468950039437?utm_medium=at11&utm_source=install',
    status: '301',
  },
  {
    source: '/<lang_code>/at11-install',
    target:
      'https://kb.samsara.com/hc/articles/27468950039437?utm_medium=at11&utm_source=install',
    status: '301',
  },
  {
    source: '/company/esg',
    target: '/company/impact-report',
    status: '301',
  },
  {
    source: '/<lang_code>/company/esg',
    target: '/<lang_code>/company/impact-report',
    status: '301',
  },
  {
    source: '/<lang_code>/gratistest',
    target: '/<lang_code>/resources/bnl-awareness-campaign',
    status: '302',
  },
  {
    source: '/<lang_code>/resources/content/bnl-awareness-campaign',
    target: '/<lang_code>/resources/bnl-awareness-campaign',
    status: '302',
  },
  {
    source: '/customers/us-logistics-solutions',
    target: '/customers',
    status: '301',
  },
  {
    source: '/<lang_code>/customers/us-logistics-solutions',
    target: '/<lang_code>/customers',
    status: '301',
  },
  {
    source: '/opt-out',
    target: '/resources/content/email-preferences-center',
    status: '301',
  },
  {
    source: '/<lang_code>/opt-out',
    target: '/<lang_code>/resources/content/email-preferences-center',
    status: '301',
  },
  {
    source: '/industrial-trial',
    target: '/pricing',
    status: '301',
  },
  {
    source: '/<lang_code>/industrial-trial',
    target: '/<lang_code>/pricing',
    status: '301',
  },
  {
    source: '/.well-known/com.apple.remotemanagement',
    target:
      'https://a.simplemdm.com/device/enrollment/well_known/d96e9fd1-316a-4b1d-9c0c-217d75d7ce2f',
    status: '301',
  },
  {
    source: '/support/eol-policy',
    target:
      'https://kb.samsara.com/hc/en-us/articles/29275145998349-Samsara-Product-End-of-Life-EOL-Policy',
    status: '301',
  },
  {
    source: '/<lang_code>/support/eol-policy',
    target:
      'https://kb.samsara.com/hc/en-us/articles/29275145998349-Samsara-Product-End-of-Life-EOL-Policy',
    status: '301',
  },
  {
    source: '/page/<slug>',
    ignore: '/page/pricing',
    target: '/pages/<slug>',
    status: '301',
  },
  {
    source: '/<lang_code>/page/<slug>',
    ignore: '/page/pricing',
    target: '/<lang_code>/pages/<slug>',
    status: '301',
  },
];
