import { redirectList } from '~/constants/redirects-base.js';

export default defineNuxtRouteMiddleware((to) => {
  const LOCAL_TEST = '(fr|uk|de|mx|be|ca|fr-ca|nl)';
  const SLUG_TEST = '([^/]+)'; // Strict slug match
  const rmTrailing = to.path.replace(/\/+$/, ''); // Remove trailing slash
  const queryParams = to.query;
  const queryString = new URLSearchParams(queryParams).toString();

  // Function to build regex
  const buildRegex = (source) => {
    let regexString = `^${source}`;

    if (source.includes('<lang_code>')) {
      regexString = regexString.replace('<lang_code>', LOCAL_TEST);
    }

    if (source.includes('<slug>')) {
      regexString = regexString.replace('<slug>', SLUG_TEST);
    }

    regexString += '$';

    return new RegExp(regexString);
  };

  // Function to replace placeholders in the target
  const replacePlaceholders = (target, matches) => {
    let result = target;

    if (target.includes('<lang_code>')) {
      result = result.replace('<lang_code>', matches[1]);
    }

    if (target.includes('<slug>')) {
      const slugIndex = target.includes('<lang_code>') ? 2 : 1;
      result = result.replace('<slug>', matches[slugIndex]);
    }

    return result;
  };

  // Find the appropriate redirect rule
  const redirect = redirectList.find((r) => {
    const regex = buildRegex(r.source);
    const matches = rmTrailing.match(regex);

    if (matches) {
      // Check the ignore logic
      if (r.ignore && rmTrailing.includes(r.ignore)) {
        return false;
      }

      const newTarget = replacePlaceholders(r.target, matches);
      const finalUrl = `${newTarget}${queryString ? `?${queryString}` : ''}`;

      // Return the new target URL for navigation
      return navigateTo(finalUrl, {
        redirectCode: r.status || 301,
        external: true,
      });
    }
    return false;
  });
});
