import { default as api_45legacyoikwepyRcvMeta } from "/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardshtQ3wCjbhOMeta } from "/codebuild/output/src111283733/src/horizon/pages/awards.vue?macro=true";
import { default as programswjJnfECJFfMeta } from "/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93KkiwmuIM5VMeta } from "/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93rQyPg9qg2QMeta } from "/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexBNovUKj8RpMeta } from "/codebuild/output/src111283733/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta } from "/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93N5QODW0IEqMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93vLo2IuJ8QPMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutCVjZDOdEQ7Meta } from "/codebuild/output/src111283733/src/horizon/pages/company/about.vue?macro=true";
import { default as indexy5dyYDgqXkMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productV7FdN3qVvGMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexovNVzskleCMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as sales5PIzu9wbizMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archiveesGQkWQTIMMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as index9FET6TfenRMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexHTQGQU5pQyMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93va6UoOTUC2Meta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexb9BSXnj9c0Meta } from "/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as contact7GEnE6f9KGMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/contact.vue?macro=true";
import { default as diversity4EqhJjiIIGMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue?macro=true";
import { default as index97St8XYaZaMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyjfG8cQJz7hMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetyotcvxdptPFMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilitytAsMkLpE8BMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsVwEYGEkjlGMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexABmLxef3XrMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesnWH2s0Yz33Meta } from "/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacy0GIyazNMhPMeta } from "/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45tooldeFqmtDk69Meta } from "/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93msGQiSJUvPMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93juFRcYKCUFMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as index1XO2sxcB08Meta } from "/codebuild/output/src111283733/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_934luYCiu6E5Meta } from "/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93lR7GRLzkbvMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexTwJjlT5ahtMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93RVrRokMDplMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondZWcvUJxFBQMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performance5Ev4lBXSRKMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceTytKff0VnZMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indext5I8x5T6oiMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetHPYtcUikPEMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performancecjlnvG4mQ8Meta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performance7Djy7dUrApMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorYuOcrwDI6NMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexjhLkvqN0eZMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetczKpacPO8VMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetRJQgXxYQvoMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexO9D0Fud8LSMeta } from "/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93vmTcxjT0qrMeta } from "/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexLNygw0Dj2rMeta } from "/codebuild/output/src111283733/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45appREYg0JKwjVMeta } from "/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93lfJ2ICJA22Meta } from "/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93z8Orp4c6WCMeta } from "/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93CiLLwYwjjmMeta } from "/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93ru10txtIovMeta } from "/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93ynplcWPeTLMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentBwKTdJeg0lMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexIFIwXadyR9Meta } from "/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetspqmM2wyHBfMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45cams0OnNGenrZGMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as index4MY5WOZqarMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programOEaosGOGxfMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roidQpxHnwBImMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexT08snkDkEYMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futuremvC9GsPalsMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsnnJfzYhkl6Meta } from "/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexuAuiGufyWiMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataRxuhOVU2JTMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93q1BhJv4h8nMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexweH39kW8GYMeta } from "/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexN7LK1OSPa0Meta } from "/codebuild/output/src111283733/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_933xvVclAqoXMeta } from "/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93xxv2v2jFEFMeta } from "/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexBucCWDVF4EMeta } from "/codebuild/output/src111283733/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesSlbWR0lJ7SMeta } from "/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexJ0ZSZHTKdVMeta } from "/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexZ7632vnROXMeta } from "/codebuild/output/src111283733/src/horizon/pages/invite/index.vue?macro=true";
import { default as learn_45more0C2DIMpEb6Meta } from "/codebuild/output/src111283733/src/horizon/pages/learn-more.vue?macro=true";
import { default as candidate_45privacy_45noticeZL7UUhTtgHMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentJCV6CHHz7fMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyKBYWsnQz6yMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendum3bPj5aPTH8Meta } from "/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsNmu98MuzTxMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantywJ08cPhYn1Meta } from "/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slaWiTSeLBVcnMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductyhf7mHcRHKMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceIntryoxzZ3Meta } from "/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyieM98R0KzsMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataMGMvlBanabMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityYSQ0TI5NqrMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportfdJGkL8q8rMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45service6AF3sRMOOMMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choices4cqBisOsYWMeta } from "/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricing3hina8f1LsMeta } from "/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93i1GWZM5sAkMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_938W9IqS01aEMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingWLBRf3WodDMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45cams7UJIPhs1fzMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionStI5wQ5IZpMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementb63pdoz8OLMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwide1RkWdGPZf7Meta } from "/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingZCsv4ZH6hDMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirstkP79Cn0kouMeta } from "/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricinggq5lGTHMCPMeta } from "/codebuild/output/src111283733/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_9356tSIwBhtFMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93YC5c9SpZjVMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingG3eN65G2KWMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingCKETWePZRQMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoring7SzuEGLRYlMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersTqxdyLTngsMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingbGBHEAtO96Meta } from "/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexsoZPceSfbmMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93ysWozM7q1HMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexndrS7DTg8OMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingGc39sXDjytMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexd0WcxzCcYjMeta } from "/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as referralsnavUxNWfpQMeta } from "/codebuild/output/src111283733/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93vq6l7T2Gc4Meta } from "/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsYvYG0NtDCuMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93TvBuRFijRuMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centeraSjReAfR0lMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as _91slug_93Kbv8jF0YpKMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93uW6jLEHX5WMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexaWPogfYZNyMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as index0OuwlbhMPQMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93pX4gThOKsCMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93VbQMHniFIoMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexXZWb7XxVotMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsarau33HuDvqpUMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programscdRIYulXZ4Meta } from "/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexdMXdGNOUTvMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesXE3HJA2iyMMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsaraCQx8kBb640Meta } from "/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourXo73HvY954Meta } from "/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosg5xK09jIJAMeta } from "/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexKDCdZMqp3mMeta } from "/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45ventureshyfjvvlTuLMeta } from "/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitEe8gEGPYczMeta } from "/codebuild/output/src111283733/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_936BSfQAJTb5Meta } from "/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as index0EGe6OoDK4Meta } from "/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityzSCYUb74u0Meta } from "/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spacejzWLHoUhNBMeta } from "/codebuild/output/src111283733/src/horizon/pages/space.vue?macro=true";
import { default as sparkg5xIcC6graMeta } from "/codebuild/output/src111283733/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93zTg0GOKHqEMeta } from "/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93CRydx3ITBJMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93bPNWiDn1qrMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policybZuJPMcobSMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqYfQvNsvzFGMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanish7LO12oJ19sMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingrEp2JCP5B9Meta } from "/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexi5SGAMJ9m1Meta } from "/codebuild/output/src111283733/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialXj1nZooXchMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementIC80lsPkRcMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyY8HKWc8BQCMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexSoJlVYE0GoMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuressKIH6sJAi3Meta } from "/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listahotG3K6umMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesC2Xzg293xJMeta } from "/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pageco3F9W69WzMeta } from "/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingdLbFu7l6byMeta } from "/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referral1zLpYz2aVdMeta } from "/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyoubzvzfYSkHGMeta } from "/codebuild/output/src111283733/src/horizon/pages/thankyou.vue?macro=true";
import { default as elitepxsFot8aw3Meta } from "/codebuild/output/src111283733/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusIyUeUhbWPrMeta } from "/codebuild/output/src111283733/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premieru1NZQKm2kCMeta } from "/codebuild/output/src111283733/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterw9meI8hK7nMeta } from "/codebuild/output/src111283733/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processwpTGhcDw8yMeta } from "/codebuild/output/src111283733/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportWlP4swr3jmMeta } from "/codebuild/output/src111283733/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93xHmohom8UsMeta } from "/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as index99zJliDWeWMeta } from "/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93oD1PQ17dnUMeta } from "/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___ca",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___nl",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___uk",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___de",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___mx",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___fr",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyoikwepyRcvMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyoikwepyRcvMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyoikwepyRcvMeta || {},
    alias: api_45legacyoikwepyRcvMeta?.alias || [],
    redirect: api_45legacyoikwepyRcvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___en-US",
    path: awardshtQ3wCjbhOMeta?.path ?? "/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___ca",
    path: awardshtQ3wCjbhOMeta?.path ?? "/ca/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___nl",
    path: awardshtQ3wCjbhOMeta?.path ?? "/nl/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___uk",
    path: awardshtQ3wCjbhOMeta?.path ?? "/uk/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___de",
    path: awardshtQ3wCjbhOMeta?.path ?? "/de/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___mx",
    path: awardshtQ3wCjbhOMeta?.path ?? "/mx/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___fr",
    path: awardshtQ3wCjbhOMeta?.path ?? "/fr/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardshtQ3wCjbhOMeta?.name ?? "awards___fr-ca",
    path: awardshtQ3wCjbhOMeta?.path ?? "/fr-ca/awards",
    meta: awardshtQ3wCjbhOMeta || {},
    alias: awardshtQ3wCjbhOMeta?.alias || [],
    redirect: awardshtQ3wCjbhOMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___en-US",
    path: programswjJnfECJFfMeta?.path ?? "/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___ca",
    path: programswjJnfECJFfMeta?.path ?? "/ca/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___nl",
    path: programswjJnfECJFfMeta?.path ?? "/nl/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___uk",
    path: programswjJnfECJFfMeta?.path ?? "/uk/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___de",
    path: programswjJnfECJFfMeta?.path ?? "/de/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___mx",
    path: programswjJnfECJFfMeta?.path ?? "/mx/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___fr",
    path: programswjJnfECJFfMeta?.path ?? "/fr/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programswjJnfECJFfMeta?.name ?? "benefits-programs___fr-ca",
    path: programswjJnfECJFfMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programswjJnfECJFfMeta || {},
    alias: programswjJnfECJFfMeta?.alias || [],
    redirect: programswjJnfECJFfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KkiwmuIM5VMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93KkiwmuIM5VMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93KkiwmuIM5VMeta || {},
    alias: _91slug_93KkiwmuIM5VMeta?.alias || [],
    redirect: _91slug_93KkiwmuIM5VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KkiwmuIM5VMeta?.name ?? "blog-slug___ca",
    path: _91slug_93KkiwmuIM5VMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93KkiwmuIM5VMeta || {},
    alias: _91slug_93KkiwmuIM5VMeta?.alias || [],
    redirect: _91slug_93KkiwmuIM5VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KkiwmuIM5VMeta?.name ?? "blog-slug___mx",
    path: _91slug_93KkiwmuIM5VMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93KkiwmuIM5VMeta || {},
    alias: _91slug_93KkiwmuIM5VMeta?.alias || [],
    redirect: _91slug_93KkiwmuIM5VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KkiwmuIM5VMeta?.name ?? "blog-slug___uk",
    path: _91slug_93KkiwmuIM5VMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93KkiwmuIM5VMeta || {},
    alias: _91slug_93KkiwmuIM5VMeta?.alias || [],
    redirect: _91slug_93KkiwmuIM5VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KkiwmuIM5VMeta?.name ?? "blog-slug___fr",
    path: _91slug_93KkiwmuIM5VMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93KkiwmuIM5VMeta || {},
    alias: _91slug_93KkiwmuIM5VMeta?.alias || [],
    redirect: _91slug_93KkiwmuIM5VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KkiwmuIM5VMeta?.name ?? "blog-slug___de",
    path: _91slug_93KkiwmuIM5VMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93KkiwmuIM5VMeta || {},
    alias: _91slug_93KkiwmuIM5VMeta?.alias || [],
    redirect: _91slug_93KkiwmuIM5VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rQyPg9qg2QMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93rQyPg9qg2QMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93rQyPg9qg2QMeta || {},
    alias: _91slug_93rQyPg9qg2QMeta?.alias || [],
    redirect: _91slug_93rQyPg9qg2QMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rQyPg9qg2QMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93rQyPg9qg2QMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93rQyPg9qg2QMeta || {},
    alias: _91slug_93rQyPg9qg2QMeta?.alias || [],
    redirect: _91slug_93rQyPg9qg2QMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rQyPg9qg2QMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93rQyPg9qg2QMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93rQyPg9qg2QMeta || {},
    alias: _91slug_93rQyPg9qg2QMeta?.alias || [],
    redirect: _91slug_93rQyPg9qg2QMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rQyPg9qg2QMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93rQyPg9qg2QMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93rQyPg9qg2QMeta || {},
    alias: _91slug_93rQyPg9qg2QMeta?.alias || [],
    redirect: _91slug_93rQyPg9qg2QMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rQyPg9qg2QMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93rQyPg9qg2QMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93rQyPg9qg2QMeta || {},
    alias: _91slug_93rQyPg9qg2QMeta?.alias || [],
    redirect: _91slug_93rQyPg9qg2QMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rQyPg9qg2QMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93rQyPg9qg2QMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93rQyPg9qg2QMeta || {},
    alias: _91slug_93rQyPg9qg2QMeta?.alias || [],
    redirect: _91slug_93rQyPg9qg2QMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBNovUKj8RpMeta?.name ?? "blog___en-US",
    path: indexBNovUKj8RpMeta?.path ?? "/blog",
    meta: indexBNovUKj8RpMeta || {},
    alias: indexBNovUKj8RpMeta?.alias || [],
    redirect: indexBNovUKj8RpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNovUKj8RpMeta?.name ?? "blog___ca",
    path: indexBNovUKj8RpMeta?.path ?? "/ca/blog",
    meta: indexBNovUKj8RpMeta || {},
    alias: indexBNovUKj8RpMeta?.alias || [],
    redirect: indexBNovUKj8RpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNovUKj8RpMeta?.name ?? "blog___mx",
    path: indexBNovUKj8RpMeta?.path ?? "/mx/blog",
    meta: indexBNovUKj8RpMeta || {},
    alias: indexBNovUKj8RpMeta?.alias || [],
    redirect: indexBNovUKj8RpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNovUKj8RpMeta?.name ?? "blog___uk",
    path: indexBNovUKj8RpMeta?.path ?? "/uk/blog",
    meta: indexBNovUKj8RpMeta || {},
    alias: indexBNovUKj8RpMeta?.alias || [],
    redirect: indexBNovUKj8RpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNovUKj8RpMeta?.name ?? "blog___fr",
    path: indexBNovUKj8RpMeta?.path ?? "/fr/blog",
    meta: indexBNovUKj8RpMeta || {},
    alias: indexBNovUKj8RpMeta?.alias || [],
    redirect: indexBNovUKj8RpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNovUKj8RpMeta?.name ?? "blog___de",
    path: indexBNovUKj8RpMeta?.path ?? "/de/blog",
    meta: indexBNovUKj8RpMeta || {},
    alias: indexBNovUKj8RpMeta?.alias || [],
    redirect: indexBNovUKj8RpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondrY0QtljW6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N5QODW0IEqMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93N5QODW0IEqMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93N5QODW0IEqMeta || {},
    alias: _91slug_93N5QODW0IEqMeta?.alias || [],
    redirect: _91slug_93N5QODW0IEqMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___en-US",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___ca",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___nl",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___uk",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___de",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___mx",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___fr",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vLo2IuJ8QPMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93vLo2IuJ8QPMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93vLo2IuJ8QPMeta || {},
    alias: _91slug_93vLo2IuJ8QPMeta?.alias || [],
    redirect: _91slug_93vLo2IuJ8QPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___en-US",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___ca",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/ca/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___nl",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/nl/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___uk",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/uk/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___de",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/de/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___mx",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/mx/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___fr",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/fr/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutCVjZDOdEQ7Meta?.name ?? "company-about___fr-ca",
    path: aboutCVjZDOdEQ7Meta?.path ?? "/fr-ca/company/about",
    meta: aboutCVjZDOdEQ7Meta || {},
    alias: aboutCVjZDOdEQ7Meta?.alias || [],
    redirect: aboutCVjZDOdEQ7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___en-US",
    path: indexy5dyYDgqXkMeta?.path ?? "/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___ca",
    path: indexy5dyYDgqXkMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___nl",
    path: indexy5dyYDgqXkMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___uk",
    path: indexy5dyYDgqXkMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___de",
    path: indexy5dyYDgqXkMeta?.path ?? "/de/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___mx",
    path: indexy5dyYDgqXkMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___fr",
    path: indexy5dyYDgqXkMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5dyYDgqXkMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexy5dyYDgqXkMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexy5dyYDgqXkMeta || {},
    alias: indexy5dyYDgqXkMeta?.alias || [],
    redirect: indexy5dyYDgqXkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productV7FdN3qVvGMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productV7FdN3qVvGMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productV7FdN3qVvGMeta || {},
    alias: engineering_45and_45productV7FdN3qVvGMeta?.alias || [],
    redirect: engineering_45and_45productV7FdN3qVvGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___en-US",
    path: indexovNVzskleCMeta?.path ?? "/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___ca",
    path: indexovNVzskleCMeta?.path ?? "/ca/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___nl",
    path: indexovNVzskleCMeta?.path ?? "/nl/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___uk",
    path: indexovNVzskleCMeta?.path ?? "/uk/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___de",
    path: indexovNVzskleCMeta?.path ?? "/de/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___mx",
    path: indexovNVzskleCMeta?.path ?? "/mx/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___fr",
    path: indexovNVzskleCMeta?.path ?? "/fr/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexovNVzskleCMeta?.name ?? "company-careers-departments___fr-ca",
    path: indexovNVzskleCMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexovNVzskleCMeta || {},
    alias: indexovNVzskleCMeta?.alias || [],
    redirect: indexovNVzskleCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___en-US",
    path: sales5PIzu9wbizMeta?.path ?? "/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___ca",
    path: sales5PIzu9wbizMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___nl",
    path: sales5PIzu9wbizMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___uk",
    path: sales5PIzu9wbizMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___de",
    path: sales5PIzu9wbizMeta?.path ?? "/de/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___mx",
    path: sales5PIzu9wbizMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___fr",
    path: sales5PIzu9wbizMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales5PIzu9wbizMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: sales5PIzu9wbizMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: sales5PIzu9wbizMeta || {},
    alias: sales5PIzu9wbizMeta?.alias || [],
    redirect: sales5PIzu9wbizMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archiveesGQkWQTIMMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archiveesGQkWQTIMMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archiveesGQkWQTIMMeta || {},
    alias: emerging_45talent_45archiveesGQkWQTIMMeta?.alias || [],
    redirect: emerging_45talent_45archiveesGQkWQTIMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___en-US",
    path: index9FET6TfenRMeta?.path ?? "/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___ca",
    path: index9FET6TfenRMeta?.path ?? "/ca/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___nl",
    path: index9FET6TfenRMeta?.path ?? "/nl/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___uk",
    path: index9FET6TfenRMeta?.path ?? "/uk/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___de",
    path: index9FET6TfenRMeta?.path ?? "/de/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___mx",
    path: index9FET6TfenRMeta?.path ?? "/mx/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___fr",
    path: index9FET6TfenRMeta?.path ?? "/fr/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index9FET6TfenRMeta?.name ?? "company-careers___fr-ca",
    path: index9FET6TfenRMeta?.path ?? "/fr-ca/company/careers",
    meta: index9FET6TfenRMeta || {},
    alias: index9FET6TfenRMeta?.alias || [],
    redirect: index9FET6TfenRMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___en-US",
    path: indexHTQGQU5pQyMeta?.path ?? "/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___ca",
    path: indexHTQGQU5pQyMeta?.path ?? "/ca/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___nl",
    path: indexHTQGQU5pQyMeta?.path ?? "/nl/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___uk",
    path: indexHTQGQU5pQyMeta?.path ?? "/uk/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___de",
    path: indexHTQGQU5pQyMeta?.path ?? "/de/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___mx",
    path: indexHTQGQU5pQyMeta?.path ?? "/mx/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___fr",
    path: indexHTQGQU5pQyMeta?.path ?? "/fr/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexHTQGQU5pQyMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexHTQGQU5pQyMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexHTQGQU5pQyMeta || {},
    alias: indexHTQGQU5pQyMeta?.alias || [],
    redirect: indexHTQGQU5pQyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93va6UoOTUC2Meta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93va6UoOTUC2Meta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93va6UoOTUC2Meta || {},
    alias: _91slug_93va6UoOTUC2Meta?.alias || [],
    redirect: _91slug_93va6UoOTUC2Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___en-US",
    path: indexb9BSXnj9c0Meta?.path ?? "/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___ca",
    path: indexb9BSXnj9c0Meta?.path ?? "/ca/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___nl",
    path: indexb9BSXnj9c0Meta?.path ?? "/nl/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___uk",
    path: indexb9BSXnj9c0Meta?.path ?? "/uk/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___de",
    path: indexb9BSXnj9c0Meta?.path ?? "/de/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___mx",
    path: indexb9BSXnj9c0Meta?.path ?? "/mx/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___fr",
    path: indexb9BSXnj9c0Meta?.path ?? "/fr/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9BSXnj9c0Meta?.name ?? "company-careers-roles___fr-ca",
    path: indexb9BSXnj9c0Meta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexb9BSXnj9c0Meta || {},
    alias: indexb9BSXnj9c0Meta?.alias || [],
    redirect: indexb9BSXnj9c0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___en-US",
    path: contact7GEnE6f9KGMeta?.path ?? "/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___ca",
    path: contact7GEnE6f9KGMeta?.path ?? "/ca/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___nl",
    path: contact7GEnE6f9KGMeta?.path ?? "/nl/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___uk",
    path: contact7GEnE6f9KGMeta?.path ?? "/uk/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___de",
    path: contact7GEnE6f9KGMeta?.path ?? "/de/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___mx",
    path: contact7GEnE6f9KGMeta?.path ?? "/mx/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___fr",
    path: contact7GEnE6f9KGMeta?.path ?? "/fr/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact7GEnE6f9KGMeta?.name ?? "company-contact___fr-ca",
    path: contact7GEnE6f9KGMeta?.path ?? "/fr-ca/company/contact",
    meta: contact7GEnE6f9KGMeta || {},
    alias: contact7GEnE6f9KGMeta?.alias || [],
    redirect: contact7GEnE6f9KGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___en-US",
    path: diversity4EqhJjiIIGMeta?.path ?? "/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___ca",
    path: diversity4EqhJjiIIGMeta?.path ?? "/ca/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___nl",
    path: diversity4EqhJjiIIGMeta?.path ?? "/nl/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___uk",
    path: diversity4EqhJjiIIGMeta?.path ?? "/uk/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___de",
    path: diversity4EqhJjiIIGMeta?.path ?? "/de/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___mx",
    path: diversity4EqhJjiIIGMeta?.path ?? "/mx/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___fr",
    path: diversity4EqhJjiIIGMeta?.path ?? "/fr/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity4EqhJjiIIGMeta?.name ?? "company-diversity___fr-ca",
    path: diversity4EqhJjiIIGMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversity4EqhJjiIIGMeta || {},
    alias: diversity4EqhJjiIIGMeta?.alias || [],
    redirect: diversity4EqhJjiIIGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___en-US",
    path: index97St8XYaZaMeta?.path ?? "/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___ca",
    path: index97St8XYaZaMeta?.path ?? "/ca/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___nl",
    path: index97St8XYaZaMeta?.path ?? "/nl/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___uk",
    path: index97St8XYaZaMeta?.path ?? "/uk/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___de",
    path: index97St8XYaZaMeta?.path ?? "/de/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___mx",
    path: index97St8XYaZaMeta?.path ?? "/mx/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___fr",
    path: index97St8XYaZaMeta?.path ?? "/fr/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index97St8XYaZaMeta?.name ?? "company___fr-ca",
    path: index97St8XYaZaMeta?.path ?? "/fr-ca/company",
    meta: index97St8XYaZaMeta || {},
    alias: index97St8XYaZaMeta?.alias || [],
    redirect: index97St8XYaZaMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyjfG8cQJz7hMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencyjfG8cQJz7hMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencyjfG8cQJz7hMeta || {},
    alias: efficiencyjfG8cQJz7hMeta?.alias || [],
    redirect: efficiencyjfG8cQJz7hMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___en-US",
    path: safetyotcvxdptPFMeta?.path ?? "/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___ca",
    path: safetyotcvxdptPFMeta?.path ?? "/ca/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___nl",
    path: safetyotcvxdptPFMeta?.path ?? "/nl/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___uk",
    path: safetyotcvxdptPFMeta?.path ?? "/uk/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___de",
    path: safetyotcvxdptPFMeta?.path ?? "/de/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___mx",
    path: safetyotcvxdptPFMeta?.path ?? "/mx/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___fr",
    path: safetyotcvxdptPFMeta?.path ?? "/fr/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyotcvxdptPFMeta?.name ?? "company-mission-safety___fr-ca",
    path: safetyotcvxdptPFMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetyotcvxdptPFMeta || {},
    alias: safetyotcvxdptPFMeta?.alias || [],
    redirect: safetyotcvxdptPFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytAsMkLpE8BMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilitytAsMkLpE8BMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilitytAsMkLpE8BMeta || {},
    alias: sustainabilitytAsMkLpE8BMeta?.alias || [],
    redirect: sustainabilitytAsMkLpE8BMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsVwEYGEkjlGMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsVwEYGEkjlGMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsVwEYGEkjlGMeta || {},
    alias: in_45the_45newsVwEYGEkjlGMeta?.alias || [],
    redirect: in_45the_45newsVwEYGEkjlGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexABmLxef3XrMeta?.name ?? "company-news___en-US",
    path: indexABmLxef3XrMeta?.path ?? "/company/news",
    meta: indexABmLxef3XrMeta || {},
    alias: indexABmLxef3XrMeta?.alias || [],
    redirect: indexABmLxef3XrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexABmLxef3XrMeta?.name ?? "company-news___ca",
    path: indexABmLxef3XrMeta?.path ?? "/ca/company/news",
    meta: indexABmLxef3XrMeta || {},
    alias: indexABmLxef3XrMeta?.alias || [],
    redirect: indexABmLxef3XrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexABmLxef3XrMeta?.name ?? "company-news___mx",
    path: indexABmLxef3XrMeta?.path ?? "/mx/company/news",
    meta: indexABmLxef3XrMeta || {},
    alias: indexABmLxef3XrMeta?.alias || [],
    redirect: indexABmLxef3XrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexABmLxef3XrMeta?.name ?? "company-news___uk",
    path: indexABmLxef3XrMeta?.path ?? "/uk/company/news",
    meta: indexABmLxef3XrMeta || {},
    alias: indexABmLxef3XrMeta?.alias || [],
    redirect: indexABmLxef3XrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___de",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesnWH2s0Yz33Meta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesnWH2s0Yz33Meta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesnWH2s0Yz33Meta || {},
    alias: press_45releasesnWH2s0Yz33Meta?.alias || [],
    redirect: press_45releasesnWH2s0Yz33Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___en-US",
    path: privacy0GIyazNMhPMeta?.path ?? "/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___ca",
    path: privacy0GIyazNMhPMeta?.path ?? "/ca/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___nl",
    path: privacy0GIyazNMhPMeta?.path ?? "/nl/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___uk",
    path: privacy0GIyazNMhPMeta?.path ?? "/uk/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___de",
    path: privacy0GIyazNMhPMeta?.path ?? "/de/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___mx",
    path: privacy0GIyazNMhPMeta?.path ?? "/mx/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___fr",
    path: privacy0GIyazNMhPMeta?.path ?? "/fr/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy0GIyazNMhPMeta?.name ?? "company-privacy___fr-ca",
    path: privacy0GIyazNMhPMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacy0GIyazNMhPMeta || {},
    alias: privacy0GIyazNMhPMeta?.alias || [],
    redirect: privacy0GIyazNMhPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tooldeFqmtDk69Meta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45tooldeFqmtDk69Meta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45tooldeFqmtDk69Meta || {},
    alias: cost_45assessment_45tooldeFqmtDk69Meta?.alias || [],
    redirect: cost_45assessment_45tooldeFqmtDk69Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___ca",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___nl",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___uk",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___de",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___mx",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___fr",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msGQiSJUvPMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93msGQiSJUvPMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93msGQiSJUvPMeta || {},
    alias: _91slug_93msGQiSJUvPMeta?.alias || [],
    redirect: _91slug_93msGQiSJUvPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___de",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93juFRcYKCUFMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93juFRcYKCUFMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93juFRcYKCUFMeta || {},
    alias: _91slug_93juFRcYKCUFMeta?.alias || [],
    redirect: _91slug_93juFRcYKCUFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___en-US",
    path: index1XO2sxcB08Meta?.path ?? "/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___ca",
    path: index1XO2sxcB08Meta?.path ?? "/ca/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___nl",
    path: index1XO2sxcB08Meta?.path ?? "/nl/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___uk",
    path: index1XO2sxcB08Meta?.path ?? "/uk/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___de",
    path: index1XO2sxcB08Meta?.path ?? "/de/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___mx",
    path: index1XO2sxcB08Meta?.path ?? "/mx/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___fr",
    path: index1XO2sxcB08Meta?.path ?? "/fr/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index1XO2sxcB08Meta?.name ?? "customers___fr-ca",
    path: index1XO2sxcB08Meta?.path ?? "/fr-ca/customers",
    meta: index1XO2sxcB08Meta || {},
    alias: index1XO2sxcB08Meta?.alias || [],
    redirect: index1XO2sxcB08Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___ca",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___nl",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___uk",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___de",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___mx",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___fr",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934luYCiu6E5Meta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_934luYCiu6E5Meta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_934luYCiu6E5Meta || {},
    alias: _91slug_934luYCiu6E5Meta?.alias || [],
    redirect: _91slug_934luYCiu6E5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lR7GRLzkbvMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93lR7GRLzkbvMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93lR7GRLzkbvMeta || {},
    alias: _91slug_93lR7GRLzkbvMeta?.alias || [],
    redirect: _91slug_93lR7GRLzkbvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___en-US",
    path: indexTwJjlT5ahtMeta?.path ?? "/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___ca",
    path: indexTwJjlT5ahtMeta?.path ?? "/ca/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___nl",
    path: indexTwJjlT5ahtMeta?.path ?? "/nl/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___uk",
    path: indexTwJjlT5ahtMeta?.path ?? "/uk/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___de",
    path: indexTwJjlT5ahtMeta?.path ?? "/de/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___mx",
    path: indexTwJjlT5ahtMeta?.path ?? "/mx/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___fr",
    path: indexTwJjlT5ahtMeta?.path ?? "/fr/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexTwJjlT5ahtMeta?.name ?? "customers-tips___fr-ca",
    path: indexTwJjlT5ahtMeta?.path ?? "/fr-ca/customers/tips",
    meta: indexTwJjlT5ahtMeta || {},
    alias: indexTwJjlT5ahtMeta?.alias || [],
    redirect: indexTwJjlT5ahtMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RVrRokMDplMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93RVrRokMDplMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93RVrRokMDplMeta || {},
    alias: _91_46_46_46slug_93RVrRokMDplMeta?.alias || [],
    redirect: _91_46_46_46slug_93RVrRokMDplMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondZWcvUJxFBQMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondZWcvUJxFBQMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondZWcvUJxFBQMeta || {},
    alias: above_45beyondZWcvUJxFBQMeta?.alias || [],
    redirect: above_45beyondZWcvUJxFBQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance5Ev4lBXSRKMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performance5Ev4lBXSRKMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance5Ev4lBXSRKMeta || {},
    alias: excellence_45performance5Ev4lBXSRKMeta?.alias || [],
    redirect: excellence_45performance5Ev4lBXSRKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTytKff0VnZMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceTytKff0VnZMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTytKff0VnZMeta || {},
    alias: excellence_45serviceTytKff0VnZMeta?.alias || [],
    redirect: excellence_45serviceTytKff0VnZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indext5I8x5T6oiMeta?.path ?? "/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___ca",
    path: indext5I8x5T6oiMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___nl",
    path: indext5I8x5T6oiMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___uk",
    path: indext5I8x5T6oiMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___de",
    path: indext5I8x5T6oiMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___mx",
    path: indext5I8x5T6oiMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___fr",
    path: indext5I8x5T6oiMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indext5I8x5T6oiMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indext5I8x5T6oiMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indext5I8x5T6oiMeta || {},
    alias: indext5I8x5T6oiMeta?.alias || [],
    redirect: indext5I8x5T6oiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetHPYtcUikPEMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetHPYtcUikPEMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetHPYtcUikPEMeta || {},
    alias: safest_45fleetHPYtcUikPEMeta?.alias || [],
    redirect: safest_45fleetHPYtcUikPEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancecjlnvG4mQ8Meta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performancecjlnvG4mQ8Meta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performancecjlnvG4mQ8Meta || {},
    alias: driver_45performancecjlnvG4mQ8Meta?.alias || [],
    redirect: driver_45performancecjlnvG4mQ8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance7Djy7dUrApMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performance7Djy7dUrApMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance7Djy7dUrApMeta || {},
    alias: excellence_45performance7Djy7dUrApMeta?.alias || [],
    redirect: excellence_45performance7Djy7dUrApMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorYuOcrwDI6NMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovatorYuOcrwDI6NMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorYuOcrwDI6NMeta || {},
    alias: fleet_45innovatorYuOcrwDI6NMeta?.alias || [],
    redirect: fleet_45innovatorYuOcrwDI6NMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexjhLkvqN0eZMeta?.path ?? "/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexjhLkvqN0eZMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexjhLkvqN0eZMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexjhLkvqN0eZMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___de",
    path: indexjhLkvqN0eZMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexjhLkvqN0eZMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexjhLkvqN0eZMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhLkvqN0eZMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexjhLkvqN0eZMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexjhLkvqN0eZMeta || {},
    alias: indexjhLkvqN0eZMeta?.alias || [],
    redirect: indexjhLkvqN0eZMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetczKpacPO8VMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetczKpacPO8VMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetczKpacPO8VMeta || {},
    alias: public_45fleetczKpacPO8VMeta?.alias || [],
    redirect: public_45fleetczKpacPO8VMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRJQgXxYQvoMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetRJQgXxYQvoMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetRJQgXxYQvoMeta || {},
    alias: safest_45fleetRJQgXxYQvoMeta?.alias || [],
    redirect: safest_45fleetRJQgXxYQvoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRJQgXxYQvoMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetRJQgXxYQvoMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetRJQgXxYQvoMeta || {},
    alias: safest_45fleetRJQgXxYQvoMeta?.alias || [],
    redirect: safest_45fleetRJQgXxYQvoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___en-US",
    path: indexO9D0Fud8LSMeta?.path ?? "/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___ca",
    path: indexO9D0Fud8LSMeta?.path ?? "/ca/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___nl",
    path: indexO9D0Fud8LSMeta?.path ?? "/nl/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___uk",
    path: indexO9D0Fud8LSMeta?.path ?? "/uk/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___de",
    path: indexO9D0Fud8LSMeta?.path ?? "/de/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___mx",
    path: indexO9D0Fud8LSMeta?.path ?? "/mx/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___fr",
    path: indexO9D0Fud8LSMeta?.path ?? "/fr/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9D0Fud8LSMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexO9D0Fud8LSMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexO9D0Fud8LSMeta || {},
    alias: indexO9D0Fud8LSMeta?.alias || [],
    redirect: indexO9D0Fud8LSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___en-US",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___ca",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___nl",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___uk",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___de",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___mx",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___fr",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vmTcxjT0qrMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_93vmTcxjT0qrMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93vmTcxjT0qrMeta || {},
    alias: _91slug_93vmTcxjT0qrMeta?.alias || [],
    redirect: _91slug_93vmTcxjT0qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___en-US",
    path: indexLNygw0Dj2rMeta?.path ?? "/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___ca",
    path: indexLNygw0Dj2rMeta?.path ?? "/ca/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___nl",
    path: indexLNygw0Dj2rMeta?.path ?? "/nl/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___uk",
    path: indexLNygw0Dj2rMeta?.path ?? "/uk/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___de",
    path: indexLNygw0Dj2rMeta?.path ?? "/de/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___mx",
    path: indexLNygw0Dj2rMeta?.path ?? "/mx/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___fr",
    path: indexLNygw0Dj2rMeta?.path ?? "/fr/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNygw0Dj2rMeta?.name ?? "events___fr-ca",
    path: indexLNygw0Dj2rMeta?.path ?? "/fr-ca/events",
    meta: indexLNygw0Dj2rMeta || {},
    alias: indexLNygw0Dj2rMeta?.alias || [],
    redirect: indexLNygw0Dj2rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___en-US",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___ca",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___nl",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___uk",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___de",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/de/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___mx",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___fr",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appREYg0JKwjVMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45appREYg0JKwjVMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45appREYg0JKwjVMeta || {},
    alias: fleet_45appREYg0JKwjVMeta?.alias || [],
    redirect: fleet_45appREYg0JKwjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lfJ2ICJA22Meta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93lfJ2ICJA22Meta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93lfJ2ICJA22Meta || {},
    alias: _91slug_93lfJ2ICJA22Meta?.alias || [],
    redirect: _91slug_93lfJ2ICJA22Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___de",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z8Orp4c6WCMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93z8Orp4c6WCMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93z8Orp4c6WCMeta || {},
    alias: _91slug_93z8Orp4c6WCMeta?.alias || [],
    redirect: _91slug_93z8Orp4c6WCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CiLLwYwjjmMeta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_93CiLLwYwjjmMeta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_93CiLLwYwjjmMeta || {},
    alias: _91slug_93CiLLwYwjjmMeta?.alias || [],
    redirect: _91slug_93CiLLwYwjjmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___en-US",
    path: _91slug_93ru10txtIovMeta?.path ?? "/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___ca",
    path: _91slug_93ru10txtIovMeta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___nl",
    path: _91slug_93ru10txtIovMeta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___uk",
    path: _91slug_93ru10txtIovMeta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___de",
    path: _91slug_93ru10txtIovMeta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___mx",
    path: _91slug_93ru10txtIovMeta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___fr",
    path: _91slug_93ru10txtIovMeta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ru10txtIovMeta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93ru10txtIovMeta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93ru10txtIovMeta || {},
    alias: _91slug_93ru10txtIovMeta?.alias || [],
    redirect: _91slug_93ru10txtIovMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___ca",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___nl",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___uk",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___de",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___mx",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___fr",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ynplcWPeTLMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93ynplcWPeTLMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93ynplcWPeTLMeta || {},
    alias: _91slug_93ynplcWPeTLMeta?.alias || [],
    redirect: _91slug_93ynplcWPeTLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentBwKTdJeg0lMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talentBwKTdJeg0lMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentBwKTdJeg0lMeta || {},
    alias: attract_45retain_45talentBwKTdJeg0lMeta?.alias || [],
    redirect: attract_45retain_45talentBwKTdJeg0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEF9pAPXBVJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___en-US",
    path: indexIFIwXadyR9Meta?.path ?? "/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___ca",
    path: indexIFIwXadyR9Meta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___nl",
    path: indexIFIwXadyR9Meta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___uk",
    path: indexIFIwXadyR9Meta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___de",
    path: indexIFIwXadyR9Meta?.path ?? "/de/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___mx",
    path: indexIFIwXadyR9Meta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___fr",
    path: indexIFIwXadyR9Meta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIFIwXadyR9Meta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexIFIwXadyR9Meta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexIFIwXadyR9Meta || {},
    alias: indexIFIwXadyR9Meta?.alias || [],
    redirect: indexIFIwXadyR9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspqmM2wyHBfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetspqmM2wyHBfMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspqmM2wyHBfMeta || {},
    alias: protect_45optimize_45assetspqmM2wyHBfMeta?.alias || [],
    redirect: protect_45optimize_45assetspqmM2wyHBfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams0OnNGenrZGMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45cams0OnNGenrZGMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45cams0OnNGenrZGMeta || {},
    alias: best_45dash_45cams0OnNGenrZGMeta?.alias || [],
    redirect: best_45dash_45cams0OnNGenrZGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___en-US",
    path: index4MY5WOZqarMeta?.path ?? "/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___ca",
    path: index4MY5WOZqarMeta?.path ?? "/ca/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___nl",
    path: index4MY5WOZqarMeta?.path ?? "/nl/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___uk",
    path: index4MY5WOZqarMeta?.path ?? "/uk/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___de",
    path: index4MY5WOZqarMeta?.path ?? "/de/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___mx",
    path: index4MY5WOZqarMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___fr",
    path: index4MY5WOZqarMeta?.path ?? "/fr/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4MY5WOZqarMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: index4MY5WOZqarMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: index4MY5WOZqarMeta || {},
    alias: index4MY5WOZqarMeta?.alias || [],
    redirect: index4MY5WOZqarMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOEaosGOGxfMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programOEaosGOGxfMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programOEaosGOGxfMeta || {},
    alias: safety_45programOEaosGOGxfMeta?.alias || [],
    redirect: safety_45programOEaosGOGxfMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roidQpxHnwBImMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roidQpxHnwBImMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roidQpxHnwBImMeta || {},
    alias: safety_45roidQpxHnwBImMeta?.alias || [],
    redirect: safety_45roidQpxHnwBImMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___en-US",
    path: indexT08snkDkEYMeta?.path ?? "/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___ca",
    path: indexT08snkDkEYMeta?.path ?? "/ca/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___nl",
    path: indexT08snkDkEYMeta?.path ?? "/nl/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___uk",
    path: indexT08snkDkEYMeta?.path ?? "/uk/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___de",
    path: indexT08snkDkEYMeta?.path ?? "/de/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___mx",
    path: indexT08snkDkEYMeta?.path ?? "/mx/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___fr",
    path: indexT08snkDkEYMeta?.path ?? "/fr/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexT08snkDkEYMeta?.name ?? "guides___fr-ca",
    path: indexT08snkDkEYMeta?.path ?? "/fr-ca/guides",
    meta: indexT08snkDkEYMeta || {},
    alias: indexT08snkDkEYMeta?.alias || [],
    redirect: indexT08snkDkEYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuremvC9GsPalsMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futuremvC9GsPalsMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuremvC9GsPalsMeta || {},
    alias: build_45for_45the_45futuremvC9GsPalsMeta?.alias || [],
    redirect: build_45for_45the_45futuremvC9GsPalsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsnnJfzYhkl6Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsnnJfzYhkl6Meta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsnnJfzYhkl6Meta || {},
    alias: empower_45your_45teamsnnJfzYhkl6Meta?.alias || [],
    redirect: empower_45your_45teamsnnJfzYhkl6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexuAuiGufyWiMeta?.path ?? "/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___ca",
    path: indexuAuiGufyWiMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___nl",
    path: indexuAuiGufyWiMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___uk",
    path: indexuAuiGufyWiMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___de",
    path: indexuAuiGufyWiMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___mx",
    path: indexuAuiGufyWiMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___fr",
    path: indexuAuiGufyWiMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAuiGufyWiMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexuAuiGufyWiMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexuAuiGufyWiMeta || {},
    alias: indexuAuiGufyWiMeta?.alias || [],
    redirect: indexuAuiGufyWiMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataRxuhOVU2JTMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataRxuhOVU2JTMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataRxuhOVU2JTMeta || {},
    alias: take_45action_45with_45dataRxuhOVU2JTMeta?.alias || [],
    redirect: take_45action_45with_45dataRxuhOVU2JTMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q1BhJv4h8nMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93q1BhJv4h8nMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93q1BhJv4h8nMeta || {},
    alias: _91slug_93q1BhJv4h8nMeta?.alias || [],
    redirect: _91slug_93q1BhJv4h8nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexweH39kW8GYMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexweH39kW8GYMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexweH39kW8GYMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexweH39kW8GYMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexweH39kW8GYMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexweH39kW8GYMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexweH39kW8GYMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexweH39kW8GYMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexweH39kW8GYMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexweH39kW8GYMeta || {},
    alias: indexweH39kW8GYMeta?.alias || [],
    redirect: indexweH39kW8GYMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___en-US",
    path: indexN7LK1OSPa0Meta?.path ?? "/",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___ca",
    path: indexN7LK1OSPa0Meta?.path ?? "/ca",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___nl",
    path: indexN7LK1OSPa0Meta?.path ?? "/nl",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___uk",
    path: indexN7LK1OSPa0Meta?.path ?? "/uk",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___de",
    path: indexN7LK1OSPa0Meta?.path ?? "/de",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___mx",
    path: indexN7LK1OSPa0Meta?.path ?? "/mx",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___fr",
    path: indexN7LK1OSPa0Meta?.path ?? "/fr",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7LK1OSPa0Meta?.name ?? "index___fr-ca",
    path: indexN7LK1OSPa0Meta?.path ?? "/fr-ca",
    meta: indexN7LK1OSPa0Meta || {},
    alias: indexN7LK1OSPa0Meta?.alias || [],
    redirect: indexN7LK1OSPa0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___ca",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___nl",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___uk",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___de",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___mx",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___fr",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933xvVclAqoXMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_933xvVclAqoXMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_933xvVclAqoXMeta || {},
    alias: _91slug_933xvVclAqoXMeta?.alias || [],
    redirect: _91slug_933xvVclAqoXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___ca",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___nl",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___uk",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___de",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___mx",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___fr",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxv2v2jFEFMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93xxv2v2jFEFMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93xxv2v2jFEFMeta || {},
    alias: _91slug_93xxv2v2jFEFMeta?.alias || [],
    redirect: _91slug_93xxv2v2jFEFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___en-US",
    path: indexBucCWDVF4EMeta?.path ?? "/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___ca",
    path: indexBucCWDVF4EMeta?.path ?? "/ca/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___nl",
    path: indexBucCWDVF4EMeta?.path ?? "/nl/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___uk",
    path: indexBucCWDVF4EMeta?.path ?? "/uk/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___de",
    path: indexBucCWDVF4EMeta?.path ?? "/de/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___mx",
    path: indexBucCWDVF4EMeta?.path ?? "/mx/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___fr",
    path: indexBucCWDVF4EMeta?.path ?? "/fr/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexBucCWDVF4EMeta?.name ?? "industries___fr-ca",
    path: indexBucCWDVF4EMeta?.path ?? "/fr-ca/industries",
    meta: indexBucCWDVF4EMeta || {},
    alias: indexBucCWDVF4EMeta?.alias || [],
    redirect: indexBucCWDVF4EMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___en-US",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___ca",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/ca/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___nl",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/nl/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___uk",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/uk/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___de",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/de/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___mx",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/mx/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___fr",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/fr/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSlbWR0lJ7SMeta?.name ?? "insights-cities___fr-ca",
    path: citiesSlbWR0lJ7SMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiesSlbWR0lJ7SMeta || {},
    alias: citiesSlbWR0lJ7SMeta?.alias || [],
    redirect: citiesSlbWR0lJ7SMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___en-US",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___ca",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/ca/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___nl",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/nl/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___uk",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/uk/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___de",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/de/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___mx",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/mx/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___fr",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/fr/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ0ZSZHTKdVMeta?.name ?? "integrations___fr-ca",
    path: indexJ0ZSZHTKdVMeta?.path ?? "/fr-ca/integrations",
    meta: indexJ0ZSZHTKdVMeta || {},
    alias: indexJ0ZSZHTKdVMeta?.alias || [],
    redirect: indexJ0ZSZHTKdVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___en-US",
    path: indexZ7632vnROXMeta?.path ?? "/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___ca",
    path: indexZ7632vnROXMeta?.path ?? "/ca/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___nl",
    path: indexZ7632vnROXMeta?.path ?? "/nl/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___uk",
    path: indexZ7632vnROXMeta?.path ?? "/uk/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___de",
    path: indexZ7632vnROXMeta?.path ?? "/de/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___mx",
    path: indexZ7632vnROXMeta?.path ?? "/mx/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___fr",
    path: indexZ7632vnROXMeta?.path ?? "/fr/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7632vnROXMeta?.name ?? "invite___fr-ca",
    path: indexZ7632vnROXMeta?.path ?? "/fr-ca/invite",
    meta: indexZ7632vnROXMeta || {},
    alias: indexZ7632vnROXMeta?.alias || [],
    redirect: indexZ7632vnROXMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___en-US",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___ca",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/ca/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___nl",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/nl/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___uk",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/uk/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___de",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/de/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___mx",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/mx/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___fr",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/fr/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more0C2DIMpEb6Meta?.name ?? "learn-more___fr-ca",
    path: learn_45more0C2DIMpEb6Meta?.path ?? "/fr-ca/learn-more",
    meta: learn_45more0C2DIMpEb6Meta || {},
    alias: learn_45more0C2DIMpEb6Meta?.alias || [],
    redirect: learn_45more0C2DIMpEb6Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeZL7UUhTtgHMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticeZL7UUhTtgHMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeZL7UUhTtgHMeta || {},
    alias: candidate_45privacy_45noticeZL7UUhTtgHMeta?.alias || [],
    redirect: candidate_45privacy_45noticeZL7UUhTtgHMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentJCV6CHHz7fMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentJCV6CHHz7fMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentJCV6CHHz7fMeta || {},
    alias: cookie_45consentJCV6CHHz7fMeta?.alias || [],
    redirect: cookie_45consentJCV6CHHz7fMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKBYWsnQz6yMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyKBYWsnQz6yMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyKBYWsnQz6yMeta || {},
    alias: cookie_45policyKBYWsnQz6yMeta?.alias || [],
    redirect: cookie_45policyKBYWsnQz6yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum3bPj5aPTH8Meta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendum3bPj5aPTH8Meta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendum3bPj5aPTH8Meta || {},
    alias: data_45protection_45addendum3bPj5aPTH8Meta?.alias || [],
    redirect: data_45protection_45addendum3bPj5aPTH8Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsNmu98MuzTxMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsNmu98MuzTxMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsNmu98MuzTxMeta || {},
    alias: express_45order_45termsNmu98MuzTxMeta?.alias || [],
    redirect: express_45order_45termsNmu98MuzTxMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantywJ08cPhYn1Meta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantywJ08cPhYn1Meta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantywJ08cPhYn1Meta || {},
    alias: hardware_45warrantywJ08cPhYn1Meta?.alias || [],
    redirect: hardware_45warrantywJ08cPhYn1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaWiTSeLBVcnMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slaWiTSeLBVcnMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slaWiTSeLBVcnMeta || {},
    alias: hosted_45software_45slaWiTSeLBVcnMeta?.alias || [],
    redirect: hosted_45software_45slaWiTSeLBVcnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductyhf7mHcRHKMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductyhf7mHcRHKMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductyhf7mHcRHKMeta || {},
    alias: partner_45code_45of_45conductyhf7mHcRHKMeta?.alias || [],
    redirect: partner_45code_45of_45conductyhf7mHcRHKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceIntryoxzZ3Meta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceIntryoxzZ3Meta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceIntryoxzZ3Meta || {},
    alias: platform_45terms_45of_45serviceIntryoxzZ3Meta?.alias || [],
    redirect: platform_45terms_45of_45serviceIntryoxzZ3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___en-US",
    path: privacyieM98R0KzsMeta?.path ?? "/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___ca",
    path: privacyieM98R0KzsMeta?.path ?? "/ca/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___nl",
    path: privacyieM98R0KzsMeta?.path ?? "/nl/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___uk",
    path: privacyieM98R0KzsMeta?.path ?? "/uk/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___de",
    path: privacyieM98R0KzsMeta?.path ?? "/de/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___mx",
    path: privacyieM98R0KzsMeta?.path ?? "/mx/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___fr",
    path: privacyieM98R0KzsMeta?.path ?? "/fr/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyieM98R0KzsMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyieM98R0KzsMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyieM98R0KzsMeta || {},
    alias: privacyieM98R0KzsMeta?.alias || [],
    redirect: privacyieM98R0KzsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceJKET1dYYgnMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataMGMvlBanabMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdataMGMvlBanabMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataMGMvlBanabMeta || {},
    alias: requestingcustomerdataMGMvlBanabMeta?.alias || [],
    redirect: requestingcustomerdataMGMvlBanabMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___en-US",
    path: securityYSQ0TI5NqrMeta?.path ?? "/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___ca",
    path: securityYSQ0TI5NqrMeta?.path ?? "/ca/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___nl",
    path: securityYSQ0TI5NqrMeta?.path ?? "/nl/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___uk",
    path: securityYSQ0TI5NqrMeta?.path ?? "/uk/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___de",
    path: securityYSQ0TI5NqrMeta?.path ?? "/de/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___mx",
    path: securityYSQ0TI5NqrMeta?.path ?? "/mx/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___fr",
    path: securityYSQ0TI5NqrMeta?.path ?? "/fr/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityYSQ0TI5NqrMeta?.name ?? "legal-security___fr-ca",
    path: securityYSQ0TI5NqrMeta?.path ?? "/fr-ca/legal/security",
    meta: securityYSQ0TI5NqrMeta || {},
    alias: securityYSQ0TI5NqrMeta?.alias || [],
    redirect: securityYSQ0TI5NqrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfdJGkL8q8rMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportfdJGkL8q8rMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportfdJGkL8q8rMeta || {},
    alias: transparencyreportfdJGkL8q8rMeta?.alias || [],
    redirect: transparencyreportfdJGkL8q8rMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service6AF3sRMOOMMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45service6AF3sRMOOMMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45service6AF3sRMOOMMeta || {},
    alias: website_45terms_45of_45service6AF3sRMOOMMeta?.alias || [],
    redirect: website_45terms_45of_45service6AF3sRMOOMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4cqBisOsYWMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choices4cqBisOsYWMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choices4cqBisOsYWMeta || {},
    alias: your_45privacy_45choices4cqBisOsYWMeta?.alias || [],
    redirect: your_45privacy_45choices4cqBisOsYWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___en-US",
    path: pricing3hina8f1LsMeta?.path ?? "/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___ca",
    path: pricing3hina8f1LsMeta?.path ?? "/ca/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___nl",
    path: pricing3hina8f1LsMeta?.path ?? "/nl/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___uk",
    path: pricing3hina8f1LsMeta?.path ?? "/uk/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___de",
    path: pricing3hina8f1LsMeta?.path ?? "/de/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___mx",
    path: pricing3hina8f1LsMeta?.path ?? "/mx/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___fr",
    path: pricing3hina8f1LsMeta?.path ?? "/fr/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing3hina8f1LsMeta?.name ?? "page-pricing___fr-ca",
    path: pricing3hina8f1LsMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricing3hina8f1LsMeta || {},
    alias: pricing3hina8f1LsMeta?.alias || [],
    redirect: pricing3hina8f1LsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93i1GWZM5sAkMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93i1GWZM5sAkMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93i1GWZM5sAkMeta || {},
    alias: _91slug_93i1GWZM5sAkMeta?.alias || [],
    redirect: _91slug_93i1GWZM5sAkMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___en-US",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___ca",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___nl",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___uk",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___de",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___mx",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___fr",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938W9IqS01aEMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_938W9IqS01aEMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_938W9IqS01aEMeta || {},
    alias: _91slug_938W9IqS01aEMeta?.alias || [],
    redirect: _91slug_938W9IqS01aEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingWLBRf3WodDMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingWLBRf3WodDMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingWLBRf3WodDMeta || {},
    alias: asset_45trackingWLBRf3WodDMeta?.alias || [],
    redirect: asset_45trackingWLBRf3WodDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45cams7UJIPhs1fzMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45cams7UJIPhs1fzMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45cams7UJIPhs1fzMeta || {},
    alias: best_45dash_45cams7UJIPhs1fzMeta?.alias || [],
    redirect: best_45dash_45cams7UJIPhs1fzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___en-US",
    path: constructionStI5wQ5IZpMeta?.path ?? "/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___ca",
    path: constructionStI5wQ5IZpMeta?.path ?? "/ca/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___nl",
    path: constructionStI5wQ5IZpMeta?.path ?? "/nl/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___uk",
    path: constructionStI5wQ5IZpMeta?.path ?? "/uk/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___de",
    path: constructionStI5wQ5IZpMeta?.path ?? "/de/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___mx",
    path: constructionStI5wQ5IZpMeta?.path ?? "/mx/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___fr",
    path: constructionStI5wQ5IZpMeta?.path ?? "/fr/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionStI5wQ5IZpMeta?.name ?? "pages-construction___fr-ca",
    path: constructionStI5wQ5IZpMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionStI5wQ5IZpMeta || {},
    alias: constructionStI5wQ5IZpMeta?.alias || [],
    redirect: constructionStI5wQ5IZpMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementb63pdoz8OLMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementb63pdoz8OLMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementb63pdoz8OLMeta || {},
    alias: fleet_45managementb63pdoz8OLMeta?.alias || [],
    redirect: fleet_45managementb63pdoz8OLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___en-US",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___ca",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/ca/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___nl",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/nl/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___uk",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/uk/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___de",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/de/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___mx",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/mx/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___fr",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/fr/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1RkWdGPZf7Meta?.name ?? "pages-nationwide___fr-ca",
    path: nationwide1RkWdGPZf7Meta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwide1RkWdGPZf7Meta || {},
    alias: nationwide1RkWdGPZf7Meta?.alias || [],
    redirect: nationwide1RkWdGPZf7Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingZCsv4ZH6hDMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingZCsv4ZH6hDMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingZCsv4ZH6hDMeta || {},
    alias: safety_45coachingZCsv4ZH6hDMeta?.alias || [],
    redirect: safety_45coachingZCsv4ZH6hDMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___en-US",
    path: unifirstkP79Cn0kouMeta?.path ?? "/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___ca",
    path: unifirstkP79Cn0kouMeta?.path ?? "/ca/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___nl",
    path: unifirstkP79Cn0kouMeta?.path ?? "/nl/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___uk",
    path: unifirstkP79Cn0kouMeta?.path ?? "/uk/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___de",
    path: unifirstkP79Cn0kouMeta?.path ?? "/de/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___mx",
    path: unifirstkP79Cn0kouMeta?.path ?? "/mx/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___fr",
    path: unifirstkP79Cn0kouMeta?.path ?? "/fr/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstkP79Cn0kouMeta?.name ?? "pages-unifirst___fr-ca",
    path: unifirstkP79Cn0kouMeta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirstkP79Cn0kouMeta || {},
    alias: unifirstkP79Cn0kouMeta?.alias || [],
    redirect: unifirstkP79Cn0kouMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___en-US",
    path: pricinggq5lGTHMCPMeta?.path ?? "/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___ca",
    path: pricinggq5lGTHMCPMeta?.path ?? "/ca/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___nl",
    path: pricinggq5lGTHMCPMeta?.path ?? "/nl/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___uk",
    path: pricinggq5lGTHMCPMeta?.path ?? "/uk/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___de",
    path: pricinggq5lGTHMCPMeta?.path ?? "/de/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___mx",
    path: pricinggq5lGTHMCPMeta?.path ?? "/mx/prueba-gratis",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___fr",
    path: pricinggq5lGTHMCPMeta?.path ?? "/fr/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricinggq5lGTHMCPMeta?.name ?? "pricing___fr-ca",
    path: pricinggq5lGTHMCPMeta?.path ?? "/fr-ca/pricing",
    meta: pricinggq5lGTHMCPMeta || {},
    alias: pricinggq5lGTHMCPMeta?.alias || [],
    redirect: pricinggq5lGTHMCPMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9356tSIwBhtFMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_9356tSIwBhtFMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_9356tSIwBhtFMeta || {},
    alias: _91slug_9356tSIwBhtFMeta?.alias || [],
    redirect: _91slug_9356tSIwBhtFMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___en-US",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___ca",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/ca/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___nl",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/nl/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___uk",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/uk/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___de",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/de/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___mx",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/mx/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___fr",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YC5c9SpZjVMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93YC5c9SpZjVMeta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_93YC5c9SpZjVMeta || {},
    alias: _91slug_93YC5c9SpZjVMeta?.alias || [],
    redirect: _91slug_93YC5c9SpZjVMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingG3eN65G2KWMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingG3eN65G2KWMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingG3eN65G2KWMeta || {},
    alias: asset_45trackingG3eN65G2KWMeta?.alias || [],
    redirect: asset_45trackingG3eN65G2KWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingCKETWePZRQMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingCKETWePZRQMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingCKETWePZRQMeta || {},
    alias: equipment_45trackingCKETWePZRQMeta?.alias || [],
    redirect: equipment_45trackingCKETWePZRQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring7SzuEGLRYlMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoring7SzuEGLRYlMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring7SzuEGLRYlMeta || {},
    alias: reefer_45monitoring7SzuEGLRYlMeta?.alias || [],
    redirect: reefer_45monitoring7SzuEGLRYlMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersTqxdyLTngsMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailersTqxdyLTngsMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersTqxdyLTngsMeta || {},
    alias: smart_45trailersTqxdyLTngsMeta?.alias || [],
    redirect: smart_45trailersTqxdyLTngsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingbGBHEAtO96Meta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingbGBHEAtO96Meta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingbGBHEAtO96Meta || {},
    alias: trailer_45trackingbGBHEAtO96Meta?.alias || [],
    redirect: trailer_45trackingbGBHEAtO96Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___en-US",
    path: indexsoZPceSfbmMeta?.path ?? "/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___ca",
    path: indexsoZPceSfbmMeta?.path ?? "/ca/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___nl",
    path: indexsoZPceSfbmMeta?.path ?? "/nl/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___uk",
    path: indexsoZPceSfbmMeta?.path ?? "/uk/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___de",
    path: indexsoZPceSfbmMeta?.path ?? "/de/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___mx",
    path: indexsoZPceSfbmMeta?.path ?? "/mx/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___fr",
    path: indexsoZPceSfbmMeta?.path ?? "/fr/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsoZPceSfbmMeta?.name ?? "products___fr-ca",
    path: indexsoZPceSfbmMeta?.path ?? "/fr-ca/products",
    meta: indexsoZPceSfbmMeta || {},
    alias: indexsoZPceSfbmMeta?.alias || [],
    redirect: indexsoZPceSfbmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___de",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ysWozM7q1HMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93ysWozM7q1HMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93ysWozM7q1HMeta || {},
    alias: _91slug_93ysWozM7q1HMeta?.alias || [],
    redirect: _91slug_93ysWozM7q1HMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___en-US",
    path: indexndrS7DTg8OMeta?.path ?? "/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___ca",
    path: indexndrS7DTg8OMeta?.path ?? "/ca/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___nl",
    path: indexndrS7DTg8OMeta?.path ?? "/nl/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___uk",
    path: indexndrS7DTg8OMeta?.path ?? "/uk/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___de",
    path: indexndrS7DTg8OMeta?.path ?? "/de/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___mx",
    path: indexndrS7DTg8OMeta?.path ?? "/mx/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___fr",
    path: indexndrS7DTg8OMeta?.path ?? "/fr/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexndrS7DTg8OMeta?.name ?? "products-models___fr-ca",
    path: indexndrS7DTg8OMeta?.path ?? "/fr-ca/products/models",
    meta: indexndrS7DTg8OMeta || {},
    alias: indexndrS7DTg8OMeta?.alias || [],
    redirect: indexndrS7DTg8OMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingGc39sXDjytMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingGc39sXDjytMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingGc39sXDjytMeta || {},
    alias: gps_45fleet_45trackingGc39sXDjytMeta?.alias || [],
    redirect: gps_45fleet_45trackingGc39sXDjytMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___en-US",
    path: indexd0WcxzCcYjMeta?.path ?? "/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___ca",
    path: indexd0WcxzCcYjMeta?.path ?? "/ca/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___nl",
    path: indexd0WcxzCcYjMeta?.path ?? "/nl/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___uk",
    path: indexd0WcxzCcYjMeta?.path ?? "/uk/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___de",
    path: indexd0WcxzCcYjMeta?.path ?? "/de/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___mx",
    path: indexd0WcxzCcYjMeta?.path ?? "/mx/products/flota",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___fr",
    path: indexd0WcxzCcYjMeta?.path ?? "/fr/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexd0WcxzCcYjMeta?.name ?? "products-telematics___fr-ca",
    path: indexd0WcxzCcYjMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexd0WcxzCcYjMeta || {},
    alias: indexd0WcxzCcYjMeta?.alias || [],
    redirect: indexd0WcxzCcYjMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___en-US",
    path: referralsnavUxNWfpQMeta?.path ?? "/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___ca",
    path: referralsnavUxNWfpQMeta?.path ?? "/ca/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___nl",
    path: referralsnavUxNWfpQMeta?.path ?? "/nl/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___uk",
    path: referralsnavUxNWfpQMeta?.path ?? "/uk/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___de",
    path: referralsnavUxNWfpQMeta?.path ?? "/de/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___mx",
    path: referralsnavUxNWfpQMeta?.path ?? "/mx/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___fr",
    path: referralsnavUxNWfpQMeta?.path ?? "/fr/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsnavUxNWfpQMeta?.name ?? "referrals___fr-ca",
    path: referralsnavUxNWfpQMeta?.path ?? "/fr-ca/referrals",
    meta: referralsnavUxNWfpQMeta || {},
    alias: referralsnavUxNWfpQMeta?.alias || [],
    redirect: referralsnavUxNWfpQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___en-US",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___ca",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___nl",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___uk",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___de",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___mx",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___fr",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vq6l7T2Gc4Meta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93vq6l7T2Gc4Meta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93vq6l7T2Gc4Meta || {},
    alias: _91slug_93vq6l7T2Gc4Meta?.alias || [],
    redirect: _91slug_93vq6l7T2Gc4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsYvYG0NtDCuMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetsYvYG0NtDCuMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetsYvYG0NtDCuMeta || {},
    alias: brand_45assetsYvYG0NtDCuMeta?.alias || [],
    redirect: brand_45assetsYvYG0NtDCuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TvBuRFijRuMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93TvBuRFijRuMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93TvBuRFijRuMeta || {},
    alias: _91slug_93TvBuRFijRuMeta?.alias || [],
    redirect: _91slug_93TvBuRFijRuMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeraSjReAfR0lMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centeraSjReAfR0lMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centeraSjReAfR0lMeta || {},
    alias: email_45preferences_45centeraSjReAfR0lMeta?.alias || [],
    redirect: email_45preferences_45centeraSjReAfR0lMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kbv8jF0YpKMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93Kbv8jF0YpKMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93Kbv8jF0YpKMeta || {},
    alias: _91slug_93Kbv8jF0YpKMeta?.alias || [],
    redirect: _91slug_93Kbv8jF0YpKMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uW6jLEHX5WMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93uW6jLEHX5WMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93uW6jLEHX5WMeta || {},
    alias: _91slug_93uW6jLEHX5WMeta?.alias || [],
    redirect: _91slug_93uW6jLEHX5WMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___en-US",
    path: indexaWPogfYZNyMeta?.path ?? "/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___ca",
    path: indexaWPogfYZNyMeta?.path ?? "/ca/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___nl",
    path: indexaWPogfYZNyMeta?.path ?? "/nl/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___uk",
    path: indexaWPogfYZNyMeta?.path ?? "/uk/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___de",
    path: indexaWPogfYZNyMeta?.path ?? "/de/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___mx",
    path: indexaWPogfYZNyMeta?.path ?? "/mx/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___fr",
    path: indexaWPogfYZNyMeta?.path ?? "/fr/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWPogfYZNyMeta?.name ?? "resources-experts___fr-ca",
    path: indexaWPogfYZNyMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexaWPogfYZNyMeta || {},
    alias: indexaWPogfYZNyMeta?.alias || [],
    redirect: indexaWPogfYZNyMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___en-US",
    path: index0OuwlbhMPQMeta?.path ?? "/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___ca",
    path: index0OuwlbhMPQMeta?.path ?? "/ca/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___nl",
    path: index0OuwlbhMPQMeta?.path ?? "/nl/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___uk",
    path: index0OuwlbhMPQMeta?.path ?? "/uk/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___de",
    path: index0OuwlbhMPQMeta?.path ?? "/de/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___mx",
    path: index0OuwlbhMPQMeta?.path ?? "/mx/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___fr",
    path: index0OuwlbhMPQMeta?.path ?? "/fr/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0OuwlbhMPQMeta?.name ?? "resources___fr-ca",
    path: index0OuwlbhMPQMeta?.path ?? "/fr-ca/resources",
    meta: index0OuwlbhMPQMeta || {},
    alias: index0OuwlbhMPQMeta?.alias || [],
    redirect: index0OuwlbhMPQMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pX4gThOKsCMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93pX4gThOKsCMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93pX4gThOKsCMeta || {},
    alias: _91slug_93pX4gThOKsCMeta?.alias || [],
    redirect: _91slug_93pX4gThOKsCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VbQMHniFIoMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93VbQMHniFIoMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93VbQMHniFIoMeta || {},
    alias: _91slug_93VbQMHniFIoMeta?.alias || [],
    redirect: _91slug_93VbQMHniFIoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___en-US",
    path: indexXZWb7XxVotMeta?.path ?? "/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___ca",
    path: indexXZWb7XxVotMeta?.path ?? "/ca/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___nl",
    path: indexXZWb7XxVotMeta?.path ?? "/nl/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___uk",
    path: indexXZWb7XxVotMeta?.path ?? "/uk/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___de",
    path: indexXZWb7XxVotMeta?.path ?? "/de/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___mx",
    path: indexXZWb7XxVotMeta?.path ?? "/mx/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___fr",
    path: indexXZWb7XxVotMeta?.path ?? "/fr/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZWb7XxVotMeta?.name ?? "resources-marketplace___fr-ca",
    path: indexXZWb7XxVotMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexXZWb7XxVotMeta || {},
    alias: indexXZWb7XxVotMeta?.alias || [],
    redirect: indexXZWb7XxVotMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarau33HuDvqpUMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsarau33HuDvqpUMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarau33HuDvqpUMeta || {},
    alias: build_45with_45samsarau33HuDvqpUMeta?.alias || [],
    redirect: build_45with_45samsarau33HuDvqpUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscdRIYulXZ4Meta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programscdRIYulXZ4Meta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscdRIYulXZ4Meta || {},
    alias: evolve_45insurance_45programscdRIYulXZ4Meta?.alias || [],
    redirect: evolve_45insurance_45programscdRIYulXZ4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___en-US",
    path: indexdMXdGNOUTvMeta?.path ?? "/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___ca",
    path: indexdMXdGNOUTvMeta?.path ?? "/ca/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___nl",
    path: indexdMXdGNOUTvMeta?.path ?? "/nl/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___uk",
    path: indexdMXdGNOUTvMeta?.path ?? "/uk/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___de",
    path: indexdMXdGNOUTvMeta?.path ?? "/de/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___mx",
    path: indexdMXdGNOUTvMeta?.path ?? "/mx/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___fr",
    path: indexdMXdGNOUTvMeta?.path ?? "/fr/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexdMXdGNOUTvMeta?.name ?? "resources-partner-programs___fr-ca",
    path: indexdMXdGNOUTvMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexdMXdGNOUTvMeta || {},
    alias: indexdMXdGNOUTvMeta?.alias || [],
    redirect: indexdMXdGNOUTvMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXE3HJA2iyMMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesXE3HJA2iyMMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesXE3HJA2iyMMeta || {},
    alias: provide_45servicesXE3HJA2iyMMeta?.alias || [],
    redirect: provide_45servicesXE3HJA2iyMMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraCQx8kBb640Meta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsaraCQx8kBb640Meta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraCQx8kBb640Meta || {},
    alias: sell_45samsaraCQx8kBb640Meta?.alias || [],
    redirect: sell_45samsaraCQx8kBb640Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___en-US",
    path: tourXo73HvY954Meta?.path ?? "/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___ca",
    path: tourXo73HvY954Meta?.path ?? "/ca/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___nl",
    path: tourXo73HvY954Meta?.path ?? "/nl/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___uk",
    path: tourXo73HvY954Meta?.path ?? "/uk/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___de",
    path: tourXo73HvY954Meta?.path ?? "/de/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___mx",
    path: tourXo73HvY954Meta?.path ?? "/mx/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___fr",
    path: tourXo73HvY954Meta?.path ?? "/fr/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourXo73HvY954Meta?.name ?? "resources-tour___fr-ca",
    path: tourXo73HvY954Meta?.path ?? "/fr-ca/resources/tour",
    meta: tourXo73HvY954Meta || {},
    alias: tourXo73HvY954Meta?.alias || [],
    redirect: tourXo73HvY954Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___en-US",
    path: videosg5xK09jIJAMeta?.path ?? "/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___ca",
    path: videosg5xK09jIJAMeta?.path ?? "/ca/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___nl",
    path: videosg5xK09jIJAMeta?.path ?? "/nl/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___uk",
    path: videosg5xK09jIJAMeta?.path ?? "/uk/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___de",
    path: videosg5xK09jIJAMeta?.path ?? "/de/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___mx",
    path: videosg5xK09jIJAMeta?.path ?? "/mx/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___fr",
    path: videosg5xK09jIJAMeta?.path ?? "/fr/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg5xK09jIJAMeta?.name ?? "resources-videos___fr-ca",
    path: videosg5xK09jIJAMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosg5xK09jIJAMeta || {},
    alias: videosg5xK09jIJAMeta?.alias || [],
    redirect: videosg5xK09jIJAMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___en-US",
    path: indexKDCdZMqp3mMeta?.path ?? "/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___ca",
    path: indexKDCdZMqp3mMeta?.path ?? "/ca/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___nl",
    path: indexKDCdZMqp3mMeta?.path ?? "/nl/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___uk",
    path: indexKDCdZMqp3mMeta?.path ?? "/uk/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___de",
    path: indexKDCdZMqp3mMeta?.path ?? "/de/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___mx",
    path: indexKDCdZMqp3mMeta?.path ?? "/mx/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___fr",
    path: indexKDCdZMqp3mMeta?.path ?? "/fr/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDCdZMqp3mMeta?.name ?? "roi-calculator___fr-ca",
    path: indexKDCdZMqp3mMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexKDCdZMqp3mMeta || {},
    alias: indexKDCdZMqp3mMeta?.alias || [],
    redirect: indexKDCdZMqp3mMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___de",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventureshyfjvvlTuLMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45ventureshyfjvvlTuLMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45ventureshyfjvvlTuLMeta || {},
    alias: samsara_45ventureshyfjvvlTuLMeta?.alias || [],
    redirect: samsara_45ventureshyfjvvlTuLMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___en-US",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___ca",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/ca/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___nl",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/nl/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___uk",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/uk/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___de",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/de/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___mx",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/mx/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___fr",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/fr/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitEe8gEGPYczMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitEe8gEGPYczMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitEe8gEGPYczMeta || {},
    alias: security_45summitEe8gEGPYczMeta?.alias || [],
    redirect: security_45summitEe8gEGPYczMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___en-US",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___ca",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___nl",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___uk",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___de",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___mx",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___fr",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936BSfQAJTb5Meta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_936BSfQAJTb5Meta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_936BSfQAJTb5Meta || {},
    alias: _91slug_936BSfQAJTb5Meta?.alias || [],
    redirect: _91slug_936BSfQAJTb5Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___en-US",
    path: index0EGe6OoDK4Meta?.path ?? "/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___ca",
    path: index0EGe6OoDK4Meta?.path ?? "/ca/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___nl",
    path: index0EGe6OoDK4Meta?.path ?? "/nl/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___uk",
    path: index0EGe6OoDK4Meta?.path ?? "/uk/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___de",
    path: index0EGe6OoDK4Meta?.path ?? "/de/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___mx",
    path: index0EGe6OoDK4Meta?.path ?? "/mx/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___fr",
    path: index0EGe6OoDK4Meta?.path ?? "/fr/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: index0EGe6OoDK4Meta?.name ?? "solutions___fr-ca",
    path: index0EGe6OoDK4Meta?.path ?? "/fr-ca/solutions",
    meta: index0EGe6OoDK4Meta || {},
    alias: index0EGe6OoDK4Meta?.alias || [],
    redirect: index0EGe6OoDK4Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___en-US",
    path: securityzSCYUb74u0Meta?.path ?? "/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___ca",
    path: securityzSCYUb74u0Meta?.path ?? "/ca/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___nl",
    path: securityzSCYUb74u0Meta?.path ?? "/nl/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___uk",
    path: securityzSCYUb74u0Meta?.path ?? "/uk/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___de",
    path: securityzSCYUb74u0Meta?.path ?? "/de/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___mx",
    path: securityzSCYUb74u0Meta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___fr",
    path: securityzSCYUb74u0Meta?.path ?? "/fr/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityzSCYUb74u0Meta?.name ?? "solutions-security___fr-ca",
    path: securityzSCYUb74u0Meta?.path ?? "/fr-ca/solutions/security",
    meta: securityzSCYUb74u0Meta || {},
    alias: securityzSCYUb74u0Meta?.alias || [],
    redirect: securityzSCYUb74u0Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___en-US",
    path: spacejzWLHoUhNBMeta?.path ?? "/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___ca",
    path: spacejzWLHoUhNBMeta?.path ?? "/ca/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___nl",
    path: spacejzWLHoUhNBMeta?.path ?? "/nl/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___uk",
    path: spacejzWLHoUhNBMeta?.path ?? "/uk/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___de",
    path: spacejzWLHoUhNBMeta?.path ?? "/de/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___mx",
    path: spacejzWLHoUhNBMeta?.path ?? "/mx/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___fr",
    path: spacejzWLHoUhNBMeta?.path ?? "/fr/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacejzWLHoUhNBMeta?.name ?? "space___fr-ca",
    path: spacejzWLHoUhNBMeta?.path ?? "/fr-ca/space",
    meta: spacejzWLHoUhNBMeta || {},
    alias: spacejzWLHoUhNBMeta?.alias || [],
    redirect: spacejzWLHoUhNBMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___en-US",
    path: sparkg5xIcC6graMeta?.path ?? "/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___ca",
    path: sparkg5xIcC6graMeta?.path ?? "/ca/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___nl",
    path: sparkg5xIcC6graMeta?.path ?? "/nl/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___uk",
    path: sparkg5xIcC6graMeta?.path ?? "/uk/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___de",
    path: sparkg5xIcC6graMeta?.path ?? "/de/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___mx",
    path: sparkg5xIcC6graMeta?.path ?? "/mx/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___fr",
    path: sparkg5xIcC6graMeta?.path ?? "/fr/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkg5xIcC6graMeta?.name ?? "spark___fr-ca",
    path: sparkg5xIcC6graMeta?.path ?? "/fr-ca/spark",
    meta: sparkg5xIcC6graMeta || {},
    alias: sparkg5xIcC6graMeta?.alias || [],
    redirect: sparkg5xIcC6graMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___ca",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___nl",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___uk",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___de",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___mx",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___fr",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zTg0GOKHqEMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93zTg0GOKHqEMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93zTg0GOKHqEMeta || {},
    alias: _91slug_93zTg0GOKHqEMeta?.alias || [],
    redirect: _91slug_93zTg0GOKHqEMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CRydx3ITBJMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93CRydx3ITBJMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93CRydx3ITBJMeta || {},
    alias: _91slug_93CRydx3ITBJMeta?.alias || [],
    redirect: _91slug_93CRydx3ITBJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___en-US",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___ca",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___nl",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___uk",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___de",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___mx",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___fr",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPNWiDn1qrMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93bPNWiDn1qrMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93bPNWiDn1qrMeta || {},
    alias: _91slug_93bPNWiDn1qrMeta?.alias || [],
    redirect: _91slug_93bPNWiDn1qrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policybZuJPMcobSMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policybZuJPMcobSMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policybZuJPMcobSMeta || {},
    alias: apps_45privacy_45policybZuJPMcobSMeta?.alias || [],
    redirect: apps_45privacy_45policybZuJPMcobSMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqYfQvNsvzFGMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqYfQvNsvzFGMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqYfQvNsvzFGMeta || {},
    alias: customer_45referrals_45faqYfQvNsvzFGMeta?.alias || [],
    redirect: customer_45referrals_45faqYfQvNsvzFGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7LO12oJ19sMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanish7LO12oJ19sMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanish7LO12oJ19sMeta || {},
    alias: driver_45training_45spanish7LO12oJ19sMeta?.alias || [],
    redirect: driver_45training_45spanish7LO12oJ19sMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___ca",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___nl",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___uk",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___de",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___mx",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___fr",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingrEp2JCP5B9Meta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingrEp2JCP5B9Meta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingrEp2JCP5B9Meta || {},
    alias: driver_45trainingrEp2JCP5B9Meta?.alias || [],
    redirect: driver_45trainingrEp2JCP5B9Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___en-US",
    path: indexi5SGAMJ9m1Meta?.path ?? "/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___ca",
    path: indexi5SGAMJ9m1Meta?.path ?? "/ca/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___nl",
    path: indexi5SGAMJ9m1Meta?.path ?? "/nl/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___uk",
    path: indexi5SGAMJ9m1Meta?.path ?? "/uk/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___de",
    path: indexi5SGAMJ9m1Meta?.path ?? "/de/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___mx",
    path: indexi5SGAMJ9m1Meta?.path ?? "/mx/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___fr",
    path: indexi5SGAMJ9m1Meta?.path ?? "/fr/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5SGAMJ9m1Meta?.name ?? "support___fr-ca",
    path: indexi5SGAMJ9m1Meta?.path ?? "/fr-ca/support",
    meta: indexi5SGAMJ9m1Meta || {},
    alias: indexi5SGAMJ9m1Meta?.alias || [],
    redirect: indexi5SGAMJ9m1Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___en-US",
    path: industrialXj1nZooXchMeta?.path ?? "/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___ca",
    path: industrialXj1nZooXchMeta?.path ?? "/ca/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___nl",
    path: industrialXj1nZooXchMeta?.path ?? "/nl/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___uk",
    path: industrialXj1nZooXchMeta?.path ?? "/uk/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___de",
    path: industrialXj1nZooXchMeta?.path ?? "/de/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___mx",
    path: industrialXj1nZooXchMeta?.path ?? "/mx/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___fr",
    path: industrialXj1nZooXchMeta?.path ?? "/fr/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialXj1nZooXchMeta?.name ?? "support-industrial___fr-ca",
    path: industrialXj1nZooXchMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialXj1nZooXchMeta || {},
    alias: industrialXj1nZooXchMeta?.alias || [],
    redirect: industrialXj1nZooXchMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementIC80lsPkRcMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementIC80lsPkRcMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementIC80lsPkRcMeta || {},
    alias: modern_45slavery_45statementIC80lsPkRcMeta?.alias || [],
    redirect: modern_45slavery_45statementIC80lsPkRcMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyY8HKWc8BQCMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyY8HKWc8BQCMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyY8HKWc8BQCMeta || {},
    alias: apps_45privacy_45policyY8HKWc8BQCMeta?.alias || [],
    redirect: apps_45privacy_45policyY8HKWc8BQCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___en-US",
    path: indexSoJlVYE0GoMeta?.path ?? "/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___ca",
    path: indexSoJlVYE0GoMeta?.path ?? "/ca/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___nl",
    path: indexSoJlVYE0GoMeta?.path ?? "/nl/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___uk",
    path: indexSoJlVYE0GoMeta?.path ?? "/uk/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___de",
    path: indexSoJlVYE0GoMeta?.path ?? "/de/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___mx",
    path: indexSoJlVYE0GoMeta?.path ?? "/mx/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___fr",
    path: indexSoJlVYE0GoMeta?.path ?? "/fr/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoJlVYE0GoMeta?.name ?? "support-privacy___fr-ca",
    path: indexSoJlVYE0GoMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexSoJlVYE0GoMeta || {},
    alias: indexSoJlVYE0GoMeta?.alias || [],
    redirect: indexSoJlVYE0GoMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___de",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuressKIH6sJAi3Meta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuressKIH6sJAi3Meta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuressKIH6sJAi3Meta || {},
    alias: special_45featuressKIH6sJAi3Meta?.alias || [],
    redirect: special_45featuressKIH6sJAi3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listahotG3K6umMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listahotG3K6umMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listahotG3K6umMeta || {},
    alias: subprocessor_45listahotG3K6umMeta?.alias || [],
    redirect: subprocessor_45listahotG3K6umMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___en-US",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___ca",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/ca/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___uk",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/uk/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesC2Xzg293xJMeta?.name ?? "support-support-kb-articles___fr-ca",
    path: support_45kb_45articlesC2Xzg293xJMeta?.path ?? "/fr-ca/support/support-kb-articles",
    meta: support_45kb_45articlesC2Xzg293xJMeta || {},
    alias: support_45kb_45articlesC2Xzg293xJMeta?.alias || [],
    redirect: support_45kb_45articlesC2Xzg293xJMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageco3F9W69WzMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pageco3F9W69WzMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pageco3F9W69WzMeta || {},
    alias: thank_45you_45pageco3F9W69WzMeta?.alias || [],
    redirect: thank_45you_45pageco3F9W69WzMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingdLbFu7l6byMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingdLbFu7l6byMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingdLbFu7l6byMeta || {},
    alias: thankyou_45recruitingdLbFu7l6byMeta?.alias || [],
    redirect: thankyou_45recruitingdLbFu7l6byMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral1zLpYz2aVdMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referral1zLpYz2aVdMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referral1zLpYz2aVdMeta || {},
    alias: thankyou_45referral1zLpYz2aVdMeta?.alias || [],
    redirect: thankyou_45referral1zLpYz2aVdMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___en-US",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___ca",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/ca/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___nl",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/nl/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___uk",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/uk/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___de",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/de/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___mx",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/mx/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___fr",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/fr/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubzvzfYSkHGMeta?.name ?? "thankyou___fr-ca",
    path: thankyoubzvzfYSkHGMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyoubzvzfYSkHGMeta || {},
    alias: thankyoubzvzfYSkHGMeta?.alias || [],
    redirect: thankyoubzvzfYSkHGMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: elitepxsFot8aw3Meta?.name ?? "tiers-elite___uk",
    path: elitepxsFot8aw3Meta?.path ?? "/uk/tiers/elite",
    meta: elitepxsFot8aw3Meta || {},
    alias: elitepxsFot8aw3Meta?.alias || [],
    redirect: elitepxsFot8aw3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitepxsFot8aw3Meta?.name ?? "tiers-elite___en-US",
    path: elitepxsFot8aw3Meta?.path ?? "/tiers/elite",
    meta: elitepxsFot8aw3Meta || {},
    alias: elitepxsFot8aw3Meta?.alias || [],
    redirect: elitepxsFot8aw3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitepxsFot8aw3Meta?.name ?? "tiers-elite___ca",
    path: elitepxsFot8aw3Meta?.path ?? "/ca/tiers/elite",
    meta: elitepxsFot8aw3Meta || {},
    alias: elitepxsFot8aw3Meta?.alias || [],
    redirect: elitepxsFot8aw3Meta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusIyUeUhbWPrMeta?.name ?? "tiers-plus___uk",
    path: plusIyUeUhbWPrMeta?.path ?? "/uk/tiers/plus",
    meta: plusIyUeUhbWPrMeta || {},
    alias: plusIyUeUhbWPrMeta?.alias || [],
    redirect: plusIyUeUhbWPrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusIyUeUhbWPrMeta?.name ?? "tiers-plus___en-US",
    path: plusIyUeUhbWPrMeta?.path ?? "/tiers/plus",
    meta: plusIyUeUhbWPrMeta || {},
    alias: plusIyUeUhbWPrMeta?.alias || [],
    redirect: plusIyUeUhbWPrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusIyUeUhbWPrMeta?.name ?? "tiers-plus___ca",
    path: plusIyUeUhbWPrMeta?.path ?? "/ca/tiers/plus",
    meta: plusIyUeUhbWPrMeta || {},
    alias: plusIyUeUhbWPrMeta?.alias || [],
    redirect: plusIyUeUhbWPrMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premieru1NZQKm2kCMeta?.name ?? "tiers-premier___uk",
    path: premieru1NZQKm2kCMeta?.path ?? "/uk/tiers/premier",
    meta: premieru1NZQKm2kCMeta || {},
    alias: premieru1NZQKm2kCMeta?.alias || [],
    redirect: premieru1NZQKm2kCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premieru1NZQKm2kCMeta?.name ?? "tiers-premier___en-US",
    path: premieru1NZQKm2kCMeta?.path ?? "/tiers/premier",
    meta: premieru1NZQKm2kCMeta || {},
    alias: premieru1NZQKm2kCMeta?.alias || [],
    redirect: premieru1NZQKm2kCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premieru1NZQKm2kCMeta?.name ?? "tiers-premier___ca",
    path: premieru1NZQKm2kCMeta?.path ?? "/ca/tiers/premier",
    meta: premieru1NZQKm2kCMeta || {},
    alias: premieru1NZQKm2kCMeta?.alias || [],
    redirect: premieru1NZQKm2kCMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterw9meI8hK7nMeta?.name ?? "tiers-starter___uk",
    path: starterw9meI8hK7nMeta?.path ?? "/uk/tiers/starter",
    meta: starterw9meI8hK7nMeta || {},
    alias: starterw9meI8hK7nMeta?.alias || [],
    redirect: starterw9meI8hK7nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterw9meI8hK7nMeta?.name ?? "tiers-starter___en-US",
    path: starterw9meI8hK7nMeta?.path ?? "/tiers/starter",
    meta: starterw9meI8hK7nMeta || {},
    alias: starterw9meI8hK7nMeta?.alias || [],
    redirect: starterw9meI8hK7nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterw9meI8hK7nMeta?.name ?? "tiers-starter___ca",
    path: starterw9meI8hK7nMeta?.path ?? "/ca/tiers/starter",
    meta: starterw9meI8hK7nMeta || {},
    alias: starterw9meI8hK7nMeta?.alias || [],
    redirect: starterw9meI8hK7nMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___en-US",
    path: processwpTGhcDw8yMeta?.path ?? "/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___ca",
    path: processwpTGhcDw8yMeta?.path ?? "/ca/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___nl",
    path: processwpTGhcDw8yMeta?.path ?? "/nl/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___uk",
    path: processwpTGhcDw8yMeta?.path ?? "/uk/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___de",
    path: processwpTGhcDw8yMeta?.path ?? "/de/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___mx",
    path: processwpTGhcDw8yMeta?.path ?? "/mx/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___fr",
    path: processwpTGhcDw8yMeta?.path ?? "/fr/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processwpTGhcDw8yMeta?.name ?? "trial-process___fr-ca",
    path: processwpTGhcDw8yMeta?.path ?? "/fr-ca/trial/process",
    meta: processwpTGhcDw8yMeta || {},
    alias: processwpTGhcDw8yMeta?.alias || [],
    redirect: processwpTGhcDw8yMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportWlP4swr3jmMeta?.name ?? "warranty-support___uk",
    path: supportWlP4swr3jmMeta?.path ?? "/uk/warranty/support",
    meta: supportWlP4swr3jmMeta || {},
    alias: supportWlP4swr3jmMeta?.alias || [],
    redirect: supportWlP4swr3jmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportWlP4swr3jmMeta?.name ?? "warranty-support___en-US",
    path: supportWlP4swr3jmMeta?.path ?? "/warranty/support",
    meta: supportWlP4swr3jmMeta || {},
    alias: supportWlP4swr3jmMeta?.alias || [],
    redirect: supportWlP4swr3jmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportWlP4swr3jmMeta?.name ?? "warranty-support___ca",
    path: supportWlP4swr3jmMeta?.path ?? "/ca/warranty/support",
    meta: supportWlP4swr3jmMeta || {},
    alias: supportWlP4swr3jmMeta?.alias || [],
    redirect: supportWlP4swr3jmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportWlP4swr3jmMeta?.name ?? "warranty-support___ie",
    path: supportWlP4swr3jmMeta?.path ?? "/ie/warranty/support",
    meta: supportWlP4swr3jmMeta || {},
    alias: supportWlP4swr3jmMeta?.alias || [],
    redirect: supportWlP4swr3jmMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___de",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xHmohom8UsMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93xHmohom8UsMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93xHmohom8UsMeta || {},
    alias: _91slug_93xHmohom8UsMeta?.alias || [],
    redirect: _91slug_93xHmohom8UsMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___en-US",
    path: index99zJliDWeWMeta?.path ?? "/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___ca",
    path: index99zJliDWeWMeta?.path ?? "/ca/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___nl",
    path: index99zJliDWeWMeta?.path ?? "/nl/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___uk",
    path: index99zJliDWeWMeta?.path ?? "/uk/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___de",
    path: index99zJliDWeWMeta?.path ?? "/de/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___mx",
    path: index99zJliDWeWMeta?.path ?? "/mx/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___fr",
    path: index99zJliDWeWMeta?.path ?? "/fr/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index99zJliDWeWMeta?.name ?? "webinars___fr-ca",
    path: index99zJliDWeWMeta?.path ?? "/fr-ca/webinars",
    meta: index99zJliDWeWMeta || {},
    alias: index99zJliDWeWMeta?.alias || [],
    redirect: index99zJliDWeWMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oD1PQ17dnUMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93oD1PQ17dnUMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93oD1PQ17dnUMeta || {},
    alias: _91slug_93oD1PQ17dnUMeta?.alias || [],
    redirect: _91slug_93oD1PQ17dnUMeta?.redirect,
    component: () => import("/codebuild/output/src111283733/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]